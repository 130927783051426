<template>
  <v-container fluid class="admin-page">
    <v-row align="center" justify="space-around">
      <v-col cols="6">
        <h2>Inventory</h2>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Search Product Name"
              append-icon="mdi-magnify"
              solo
              class="shrink"
              clearable
              dense
              v-model="searchQuery"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" class="fill-height">
            <v-btn @click.prevent="refreshData()">
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <router-link to="/dashboard/add/product">
              <v-btn
                class="text--futura text--white text-capitalize"
                dark
                block
              >
                Add Product
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-start">
      <v-col cols="3" class="crystal-admin-form">
        <label class="text--futura">Status</label>
        <v-select
          :items="items"
          item-text="text"
          item-value="value"
          v-model="filterStatus"
        ></v-select>
        <!-- label="Active, Inactive and Draft" -->
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <Alert />
        <v-data-table
          :headers="headers"
          :items="products"
          :sort-by.sync="sortBy"
          :options="pagination"
          @update:options="paginate"
          :footer-props="{
              'items-per-page-options': listSize,
          }"
          @update:items-per-page="getItemPerPage"
          class="elevation-1"
        >
          <template v-slot:[`item.gross_price`]="{ item }">
            <td>{{ formatHarga(item.gross_price) }}</td>
          </template>
          <template v-slot:[`item.retail_price`]="{ item }">
            <td>{{ formatHarga(item.retail_price) }}</td>
          </template>
          <template v-slot:[`item.net_weight`]="{ item }">
            <td>{{ item.net_weight ? item.net_weight : 0 }} gr</td>
          </template>
          <template v-slot:[`item.gross_weight`]="{ item }">
            <td>{{ item.gross_weight ? item.gross_weight : 0 }} gr</td>
          </template>
          <template v-slot:[`item.ItemAssemblyLine.qty`]="{ item }">
            <td>{{ countStock(item.ItemAssemblyLines) }}</td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <!-- <v-icon small text @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            > -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DeleteDialog
      :message="`Remove Item ${item.name} ?`"
      :item="item"
      :showDelete="showDelete"
      @deleteItem="deleteProduct"
      @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import server from "../api";
import DeleteDialog from "../components/DeleteDialog.vue";
import Alert from "../components/Alert.vue";

export default {
  name: "Inventory",
  components: {
    DeleteDialog,
    Alert,
  },
  data() {
    return {
      filterStatus: this.$session.get("inventoryStatus") || "",
      items: [
        {
          text: "All",
          value: "",
        },
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },
        {
          text: "Draft",
          value: 2,
        },
      ],
      headers: [
        {
          text: "SKU",
          align: "start",
          sortable: true,
          value: "sku",
        },
        { text: "Product Name", value: "name" },
        { text: "Category", value: "item_category.name" },
        { text: "BPOM", value: "bpom_md" },
        { text: "Gross Price", value: "gross_price" },
        { text: "Retail Price", value: "retail_price" },
        //{ text: "Stock", value: "ItemAssemblyLine.qty" },
        //{ text: "Net Weight", value: "net_weight" },
        //{ text: "Gross Weight", value: "gross_weight" },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      sortBy: 'sku',
      sortDesc: false,
      showDelete: false,
      item: {},
      searchQuery: this.$session.get("inventoryName") || "",
      listSize: [15, 25, 50, 100, -1],
      pagination: {
          descending: !!this.$route.query.desc,
          itemsPerPage: +this.$session.get("inventoryItemPerPage") || 15,
          page: +this.$session.get("inventoryPage") || 1,
      },
    };
  },
  beforeRouteUpdate(to, from, next) {
      this.fetchData(to);
      next();
  },
  methods: {
    getItemPerPage(val) {
        const query = this.$route.query;
        const obj = Object.assign({}, query);
        obj.per_page = +val;
        this.$router.replace({
            ...this.$router.currentRoute,
            query: obj
        });
    },
    countStock(arr) {
      let stock = 0;
      for (let i = 0; i < arr.length; i++) {
        stock += arr[i].qty;
      }
      return stock;
    },
    refreshData() {
      this.$store.dispatch("getProducts");
    },
    updateItem(item) {
      this.item = item;
      this.item.bundle_items = JSON.stringify(this.item.bundle_items);
      server
        .put(`/products/${item.id}`, this.item, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((result) => {
          this.$store.dispatch("getProducts");
          this.$store.commit(
            "SET_SUCCESS",
            `Updated Products ${result.data.Product.name} status menjadi ${
              result.data.Product.status ? "Active" : "Inactive"
            }`
          );
        })
        .catch((err) => {
          this.$store.commit("SET_ERROR", err.response.data.errors[0].message);
        });
    },
    showDeleteDialog(item) {
      this.item = item;
      this.showDelete = true;
    },
    deleteProduct(id) {
      this.showDelete = false;
      server
        .delete(`/products/${id}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((result) => {
          this.$store.dispatch("getProducts");
          this.$store.commit(
            "SET_SUCCESS",
            `Berhasil menghapus Products ${result.data.Product.name}`
          );
        })
        .catch((err) => {
          this.$store.commit("SET_ERROR", err.response.data.errors[0].message);
        });
    },
    closeDialog() {
      this.showDelete = false;
    },
    editItem(item) {
      this.item = item;
      this.$router.push({ path: `/dashboard/edit/product/${this.item.id}` });
    },
    fetchData (route) {
      this.$store.dispatch('getProducts', route.query);
    },
    paginate (val) {
            // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
            const query = this.$route.query;
            //console.log(query.page);
            const obj = Object.assign({}, query);
            this.$session.set("inventoryPage", val.page);
            this.$session.set("inventoryItemPerPage", val.itemsPerPage);
            if (val.itemsPerPage !== this.listSize[0]) obj.per_page = val.itemsPerPage;
            if (val.descending) obj.desc = 'true';
            else delete obj.desc;
            obj.orderby = val.sortBy;
            obj.page = val.page;
            obj.per_page = val.itemsPerPage;
            // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
            let same = true;
            for (let key in query) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            // to handle the case when a KEY exists in OBJ but not in query
            for (let key in obj) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            //same = true;
            //console.log(same);
            if (same) this.fetchData(); // page has been manually reloaded in the browser
            else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: obj
                });
            }
        },
  },
  created() {
    this.$store.dispatch("getProducts", this.$route.query);
  },
  computed: {
    products() {
      var result = this.$store.state.products;
      this.$session.set("inventoryStatus", this.filterStatus);
      this.$session.set("inventoryName", this.searchQuery);
      if (this.filterStatus !== "") {
        let status = this.filterStatus;
        result = result.filter(
          (product) => product.status == status
        );
      }
      
      if (this.searchQuery !== "" && this.searchQuery !== null) {
        result = result.filter((product) =>
          product.name.toLowerCase().includes(this.searchQuery)
        );
      } 

      return result;
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
