<template>
  <v-container fluid class="admin-page">
    <v-row class="justify-space-between">
      <v-col cols="7">
        <h2>{{ type.text }} Retailer Location</h2>
      </v-col>
      <v-col cols="2">
        <v-btn
          type="submit"
          class="text--futura text--white"
          dark
          block
          @click.prevent="saveRetail()"
          :loading="buttonLoading"
          :disabled="buttonLoading"
        >
          {{ type.value == "new" ? "Save" : "Update" }}
          <template v-slot:loader>
            <v-progress-circular
              indeterminate
              color="black"
            ></v-progress-circular>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <Alert />
    </v-row>
    <v-row class="crystal-admin-form justify-space-around pt-5">
      <v-col cols="5">
        <label>Customer Name</label>
        <v-select
          solo
          :items="customers"
          item-text="firstName"
          item-value="id"
          label="Choose Customer"
          append-icon="mdi-magnify"
          v-model="retail.customerId"
        ></v-select>
        <label>Retail Type</label>
        <v-text-field
          solo
          placeholder="Enter Retail Type"
          v-model="retail.retailType"
        ></v-text-field>
        <label>Display Name</label>
        <v-text-field
          solo
          placeholder="Enter Display Name"
          v-model="retail.displayName"
        ></v-text-field>
        <label>Website</label>
        <v-text-field
          solo
          placeholder="Enter Website Url"
          v-model="retail.website"
        ></v-text-field>
        <label>Media Image</label>
          <picture-input 
          ref="pictureInput"
          width="200" 
          height="200"
          button-class="btn"
          :prefill="imageUrl"
          @change="onChange">
        </picture-input>
        <label>Whatsapp Link</label>
        <v-text-field
          solo
          placeholder="Enter whatshapp Url"
          v-model="retail.whatsappLink"
        ></v-text-field>
      </v-col>
      <v-col cols="5">
        <label>Operasional Hour</label>
        <v-text-field
          solo
          placeholder="Enter Operasional Hour"
          v-model="retail.operasionalHour"
        >
        </v-text-field>
        <label>Display Phone: </label>
        <v-text-field
          solo
          placeholder="Enter Phone Number"
          v-model="retail.displayPhone"
        >
        </v-text-field>
        <label>Address</label>
        <v-textarea solo placeholder="Enter Address" v-model="retail.address">
        </v-textarea>
        <label>Longtitude</label>
        <v-text-field
          solo
          placeholder="Enter Longtitude"
          v-model="retail.longtitude"
        >
        </v-text-field>
        <label>Latitude</label>
        <v-text-field
          solo
          placeholder="Enter Latitude"
          v-model="retail.latitude"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-end">
      <v-col cols="2">
        <v-btn
          type="submit"
          class="text--futura text--white"
          dark
          block
          @click.prevent="saveRetail()"
          :loading="buttonLoading"
          :disabled="buttonLoading"
        >
          {{ type.value == "new" ? "Save" : "Update" }}
          <template v-slot:loader>
            <v-progress-circular
              indeterminate
              color="black"
            ></v-progress-circular>
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alert from "@/components/Alert.vue";
import server from "../api";
import PictureInput from 'vue-picture-input'

export default {
  name: "RetailForm",
  components: {
    Alert,
    PictureInput
  },
  data() {
    return {
      customers: []
    }
  },
  props: {
    retail: {
      type: Object,
      default: () => {
        return {
          customerId: null,
          displayName: "",
          displayPhone: "",
          address: "",
          longtitude: "",
          latitude: "",
          website: "",
          whatsappLink: "",
          retailType: "",
          media: "",
          operasionalHour: "",
        };
      },
    },
    type: {
      type: Object,
      default: () => {
        return {
          text: "Add New",
          value: "new",
        };
      },
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveRetail() {
      this.$emit("saveRetail");
    },
    onChange() {
      this.retail.media = this.$refs.pictureInput.file
    },
    async getCustomers() {
      try {
        const { data } = await server.get("/retailer_locations/all/customers", {
          headers: {
            token:localStorage.token
          }
        });
        this.customers = data.Customers
      } catch (err) {
        this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
      }
    },
  },
  created () {
    this.getCustomers()
  },
  computed: {
    imageUrl() {
      return `${process.env.VUE_APP_API_URL}/${this.retail.media}`;
    }
  },
  mounted() {
    this.$refs.pictureInput.$el.addEventListener('change', this.onChange, false);
  }
};
</script>

<style></style>
