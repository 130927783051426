var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"8"}},[_c('h2',[_vm._v("Consignment Invoice")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"label":"Your Order Id","append-icon":"mdi-magnify","solo":"","clearable":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchOrder($event)}},model:{value:(_vm.filterOrder),callback:function ($$v) {_vm.filterOrder=$$v},expression:"filterOrder"}})],1)],1),_c('v-row',{staticClass:"justify-space-around"},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"pa-4 crystal-admin-form"},[_c('label',[_vm._v("Date")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.filterDate,"transition":"scale-transition","offset-y":"","min-width":"350px"},on:{"update:returnValue":function($event){_vm.filterDate=$event},"update:return-value":function($event){_vm.filterDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Choose Date","readonly":"","append-icon":"mdi-calendar","clearable":"","hide-details":""},model:{value:(_vm.filterDateText),callback:function ($$v) {_vm.filterDateText=$$v},expression:"filterDateText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.filterDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"pa-3 crystal-admin-form"},[_c('label',[_vm._v("Customer")]),_c('v-text-field',{attrs:{"placeholder":"Customer"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchCustomer($event)}},model:{value:(_vm.filterCustomer),callback:function ($$v) {_vm.filterCustomer=$$v},expression:"filterCustomer"}})],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.tableLoading,"loading-text":"Loading List Order...","items":_vm.orders,"server-items-length":_vm.totalOrders,"options":_vm.pagination,"footer-props":{
          'items-per-page-options': _vm.listSize,
        }},on:{"update:options":_vm.paginate,"update:items-per-page":_vm.getItemPerPage},scopedSlots:_vm._u([{key:"item.order_date",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.order_date)))])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.status == true)?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(" Done ")]):(item.status == false)?_c('v-chip',{attrs:{"color":"yellow","text-color":"black"}},[_vm._v(" On Process ")]):(item.status == null)?_c('v-chip',[_vm._v(" Pending ")]):_vm._e()]}},{key:"item.product",fn:function(ref){
        var item = ref.item;
return _vm._l((item.OrderLine.items.slice(0, 2)),function(product,index){return _c('span',{key:product.id},[_vm._v(" "+_vm._s(product.Item ? product.Item.name : "")+_vm._s((" (x" + (product.qty) + ")"))+_vm._s(index === 1 && item.OrderLine.items.length - 2 != 0 ? ("..." + (item.OrderLine.items.length - 2) + " more") : index === item.OrderLine.items.length - 1 ? "" : ",")+" ")])})}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"text--futura text--white text-capitalize",attrs:{"dark":"","block":""},on:{"click":function($event){return _vm.addInvoice(item)}}},[_vm._v(" Invoice List ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }