<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="7"
        >
            <h2>Chart of Account</h2>
        </v-col>
        <v-col
        cols="5">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Order"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/addcheck">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Print Account
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="3">       
            <div class="pa-3 crystal-admin-form">
                <label>Type</label>
                <v-text-field
                    placeholder="Choose Type"
                    hide-details
                />
            </div>
        </v-col>
        <v-col cols="3">
            <div class="pa-3 crystal-admin-form">
                <label>Date</label>
                <v-row>
                    <v-col cols="5" class="pt-0 pb-0">
                        <v-text-field
                            placeholder="Start"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="5" class="pt-0 pb-0">
                        <v-text-field
                            placeholder="End"
                            hide-details
                        />
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="3">
            <div class="pa-3 crystal-admin-form">
                <label>Split</label>
                <v-text-field
                    placeholder="Choose Type"
                    hide-details
                />
            </div>
        </v-col>
        <v-col cols="3">
            <div class="pa-3 crystal-admin-form">
                <label>Amount</label>
                <v-row>
                    <v-col cols="5" class="pt-0 pb-0">
                        <v-text-field
                            placeholder="min"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="5" class="pt-0 pb-0">
                        <v-text-field
                            placeholder="max"
                            hide-details
                        />
                    </v-col>
                </v-row>
            </div>    
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <v-data-table
                :headers="headers"
                :items="charts"
                :items-per-page="5"
                class="elevation-1"
            >
            </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'ChartAccount',
    data () {
        return {
            headers: [
                { text: 'Name', value: 'name'},
                { text: 'Type', value: 'type'},
                { text: 'Currency', value: 'currency'},
                { text: 'Balance', value: 'balance'}
            ],
            charts: [
                {
                    name: 'BCA Main',
                    type: 'bank',
                    currency: 'IDR',
                    balance: 'HQ Rupiah'
                },
                {
                    name: 'BCA Main',
                    type: 'bank',
                    currency: 'IDR',
                    balance: 'HQ Rupiah'
                },
                {
                    name: 'BCA Main',
                    type: 'bank',
                    currency: 'IDR',
                    balance: 'HQ Rupiah'
                },
                {
                    name: 'BCA Main',
                    type: 'bank',
                    currency: 'IDR',
                    balance: 'HQ Rupiah'
                }
            ]
        }
    }
}
</script>

<style>

</style>