<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="7"
        >
            <h2>Bill Items / Expense</h2>
        </v-col>
        <v-col
        cols="5">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search product, destination"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/addinternalstock">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Manual Input
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="3">
            <div class="pa-2 crystal-admin-form">
                <label>Date</label>
                <v-select
                    label="Choose Date"
                />
            </div> 
        </v-col>
        <v-col cols="3">
            <div class="pa-2 crystal-admin-form">
                <label>From - Destination</label>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="From"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="Destination"
                            hide-details
                        />
                    </v-col>
                </v-row>
            </div>       
        </v-col>
        <v-col cols="3">
            <div class="pa-2 crystal-admin-form">
                <label>Type</label>
                <v-select
                    label="Single or Bundle"
                >
                </v-select>
            </div>   
        </v-col>
        <v-col cols="3">
            <div class="pa-2 crystal-admin-form">
                <label>Quantity</label>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="min"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="max"
                            hide-details
                        />
                    </v-col>
                </v-row>
            </div>       
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <v-data-table
                :headers="headers"
                :items="vendors"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        v-model="item.status"
                        ></v-switch>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small text @click="showDeleteDialog(item)">mdi-eye</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'Expense',
    data () {
        return {
            headers: [
                { text: 'Date', value: 'date'},
                { text: 'Vendor', value: 'vendor'},
                { text: 'Ref. No.', value: 'ref_no'},
                { text: 'Disc. Date', value: 'discount'},
                { text: 'Amount Due', value: 'amount_due'},
                { text: 'Disc Used', value: 'disc_used'},
                { text: 'Cr. Used', value: 'credit_used'},
                { text: 'Amount to Pay', value: 'pay_amount'},
                { text: 'Action', value: 'actions' }
            ],
            vendors: [
                { 
                    date: '05/10/2020',
                    vendor: 'Jakarta123',
                    ref_no: '000000',
                    discount: '05/10/2020',
                    amount_due: 100000,
                    disc_used: 100,
                    credit_used: 100,
                    pay_amount: 100000
                },
                { 
                    date: '05/10/2020',
                    vendor: 'Jakarta123',
                    ref_no: '000000',
                    discount: '05/10/2020',
                    amount_due: 100000,
                    disc_used: 100,
                    credit_used: 100,
                    pay_amount: 100000
                },
                { 
                    date: '05/10/2020',
                    vendor: 'Jakarta123',
                    ref_no: '000000',
                    discount: '05/10/2020',
                    amount_due: 100000,
                    disc_used: 100,
                    credit_used: 100,
                    pay_amount: 100000
                },
                { 
                    date: '05/10/2020',
                    vendor: 'Jakarta123',
                    ref_no: '000000',
                    discount: '05/10/2020',
                    amount_due: 100000,
                    disc_used: 100,
                    credit_used: 100,
                    pay_amount: 100000
                },
            ]
        }
    }
}
</script>

<style>

</style>