<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="8"
        >
            <h2>Deposits</h2>
        </v-col>
        <v-col
        cols="4">
            <v-row>
                <v-col
                cols="8"
                >   
                    <v-text-field
                        label="Search product, destination"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="12" class="crystal-admin-form">       
            <v-row class="align-baseline">
                <v-col cols="1">
                    <label>Deposit to</label>
                </v-col>
                <v-col cols="3">
                    <v-select
                        solo
                        dense
                        label="HQ BCA Rupiah"
                        class="w-25"
                        hide-details
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-row class="justify-space-between">
                <v-col cols="4">
                    <label>Currency</label>
                    <v-select
                        solo
                        dense
                        label="Check"
                        hide-details
                    >
                    </v-select>
                    <span>EXCHANGE RATE 1 IDR = </span>
                </v-col>
                <v-col cols="4">
                    <label>Date</label>
                    <v-select
                        solo
                        dense
                        label="Choose Date"
                    >
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <label>Memo</label>
                    <v-select
                        solo
                        dense
                        label="Deposit"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="mt-2 crystal-admin-form">
        <v-col
            cols="12"
        >
            <v-data-table
                v-model="selected_deposits"
                :headers="headersDeposit"
                :items="deposits"
                :items-per-page="5"
                class="elevation-1"
                show-select
                selectable-key="receive_from"
                item-key="receive_from"
            >
            </v-data-table>
        </v-col>
        <v-col cols="12">
            <v-row class="justify-end">
                <v-col cols="4">
                    <v-row class="justify-space-around">
                            <span>Deposit Subtotal:</span>
                            <span>Rp. 250.000.000</span>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
                <v-col cols="12">
            <v-row class="justify-end">
                <v-col cols="4">
                    <v-row class="justify-space-around">
                        <span>Deposit total:</span>
                        <span>Rp. 250.000.000</span>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-end">
        <v-col cols="2">
            <v-btn
                dense
                dark
                block
                class="text-capitalize"
            >Save
            </v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'Deposit',
    data() {
        return {
            selected_deposits: [],
            headersDeposit: [
                { text: 'Receive From', value: 'receive_from'},
                { text: 'From Account', value: 'from_account'},
                { text: 'Memo', value: 'memo'},
                { text: 'CHK No.', value: 'check_no'},
                { text: 'PMT. Meth', value: 'pmt'},
                { text: 'Class', value: 'class'},
                { text: 'Amount', value: 'amount'}
            ],
            deposits: [
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                },
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                },
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                },
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                },
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                }
            ]
        }
    }
}
</script>

<style scoped>
    .w-25 {
        width: 160px;
    }
</style>