<template>
  <WarehouseForm 
    :warehouse="warehouse"
    @saveWarehouse="saveWarehouse"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import server from '../api'
import WarehouseForm from '@/components/WarehouseForm.vue'

export default {
    name: 'AddWarehouse',
    components: {
        WarehouseForm
    },
    data () {
        return {
            warehouse: {
                status: true,
                LocationName: '',
                latitude: '',
                longitude: '',
                addressLine1: '',
                city_id: '',
                phone_number: '',
            },
            buttonLoading: false
        }
    },
    methods: {
        saveWarehouse() {
            this.buttonLoading = !this.buttonLoading;
            server.post('/warehouse', {
                LocationName: this.warehouse.LocationName,
                latitude: this.warehouse.latitude,
                longitude: this.warehouse.longitude,
                addressLine1: this.warehouse.addressLine1,
                city_id: this.warehouse.city_id,
                phone_number: this.warehouse.phone_number,
                status: this.warehouse.status
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `berhasil menambahkan Warehouse ${result.data.Warehouse.LocationName}`);
                    this.$router.push({ name: 'Warehouse' })
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', err.response.data.errors.message);
                });
        }
    },
    created () {
        this.$store.dispatch('getCities')
        this.$store.dispatch('getProvinces')
    }
}
</script>

<style>

</style>