<template>
  <v-main class="background-crystal-color">
      <v-container class="container-login">
          <v-row class="justify-center align-center">
              <v-col
              cols="4"
              class="login-crystal text--futura mx-auto"
              >
                <v-img
                src="../assets/Crystal_Logo.png"
                width="103"
                height="103"
                class="mx-auto"
                />
                <span v-if="errorMessage" class="red--text">{{ errorMessage }}</span>
                <v-form
                @submit.prevent="login"
                v-model="valid"
                lazy-validation
                >
                    <label for="password">Email</label>
                    <v-text-field
                    id="email"
                    v-model="email"
                    label="Your Email"
                    :rules="emailRules"
                    solo
                    required
                    />
                    <label for="password">Password</label>
                    <v-text-field
                    :type="togglePassword ? 'password' : 'text'"
                    id="password"
                    v-model="password"
                    label="Password"
                    :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (togglePassword = !togglePassword)"
                    solo
                    required
                    >
                    </v-text-field>
                    <v-row>
                        <v-col
                        cols="12"
                        >
                            <v-btn
                                elevation="2"
                                class="text--futura bg-color-black-1 text--white"
                                block
                                :loading="loading"
                                :disabled="!valid"
                                type="submit"
                            >Login</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
              </v-col>
          </v-row>
      </v-container>
  </v-main>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import server from '../api'

export default {
    name: "Login",
    validations: {
        email: { required, email },
        password: { required }
    },
    data() {
        return {
            valid: true,
            email: '',
            password: '',
            togglePassword: String,
            loading: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be a valid email'
            ],
        }
    },
    methods: {
        login () {
            this.loading = true
            server.post('/login', {
                email: this.email,
                password: this.password
            })
                .then(results => {
                    this.loading = false
                    localStorage.setItem('token', results.data.token)
                    this.$store.commit('SET_LOGIN', true)
                    this.$router.push({ name: 'Dashboard' })
                })
                .catch(err => {
                    this.loading = false
                    this.$store.commit('SET_ERROR', err.response.data.errors.message)
                })
        }
    },
    created () {
        if(this.isLogin || localStorage.token) {
            this.$router.push({ name: 'Inventory' })
        }
    },
    computed: {
        isLogin () {
            return this.$store.state.isLogin
        },
        errorMessage () {
            return this.$store.state.errorMessage
        }
    }
}
</script>

<style>
    .login-crystal {
        background-color: white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 16px 8px;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .container-login {
        height: 100%;
        position: relative;
    }
</style>