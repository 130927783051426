<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="7"
        >
            <h2>Consignment Tracker</h2>
        </v-col>
        <v-col
        cols="5">
            <v-row>
                <v-col
                cols="10"
                >   
                    <v-text-field
                        label="Search product, destination"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="2" class="crystal-admin-form">       
            <label>Product</label>
            <v-text-field
                placeholder="Product"
            />
        </v-col>
        <v-col cols="2" class="crystal-admin-form">       
            <label>Pending Shipped</label>
            <v-row>
                <v-col cols="5">
                    <v-text-field
                        placeholder="From"
                    />
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        placeholder="To"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="2" class="crystal-admin-form">       
            <label>City</label>
            <v-text-field
                placeholder="Choose Date"
            />
        </v-col>
        <v-col cols="2" class="crystal-admin-form">       
            <label>Consignee</label>
            <v-text-field
                placeholder="Choose Date"
            />
        </v-col>
        <v-col cols="2" class="crystal-admin-form">
            <label>Pending Paid</label>    
            <v-row>
                <v-col cols="5">
                    <v-text-field
                        placeholder="min"
                    />
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        placeholder="max"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <v-data-table
                :headers="headers"
                :items="vendors"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        v-model="item.status"
                        ></v-switch>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small text @click="showDeleteDialog(item)">mdi-eye</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'ConsigmentTracker',
    data () {
        return {
            headers: [
                { text: 'Name', value: 'name'},
                { text: 'Coupon Code', value: 'code'},
                { text: 'Valid Period', value: 'valid_period'},
                { text: 'Discount', value: 'discount'},
                { text: 'Status', value: 'status'},
                { text: 'Action', value: 'actions' }
            ],
            vendors: [
                { 
                    name: 'discount',
                    code: 'oktoberceria',
                    valid_period: 'date',
                    status: true
                },
                { 
                    name: 'discount',
                    code: 'oktoberceria',
                    valid_period: 'date',
                    status: true
                },
                { 
                    name: 'discount',
                    code: 'oktoberceria',
                    valid_period: 'date',
                    status: true
                },
                { 
                    name: 'discount',
                    code: 'oktoberceria',
                    valid_period: 'date',
                    status: true
                },
            ]
        }
    }
}
</script>

<style>

</style>