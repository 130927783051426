<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center" justify="space-around">
        <v-col
        cols="6"
        >
            <h2>Warehouse List</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Warehouse"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                        v-model="searchQuery"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/warehouse">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add Warehouse
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around crystal-admin-form">
 
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <Alert />
            <v-data-table
                :headers="headers"
                :items="warehouses"
                :items-per-page="15"
                class="elevation-1"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        v-model="item.status"
                        @click="updateStatus(item)"
                    ></v-switch>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DeleteDialog 
        :message="`Remove Warehouse ${warehouse.LocationName} ?`"
        :item="warehouse"
        :showDelete="showDelete"
        @deleteItem="deleteWarehouse"
        @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog.vue'
import Alert from '@/components/Alert.vue'
import server from '../api'

export default {
    name: 'ShipFrom',
    components: {
        DeleteDialog,
        Alert
    },
    data () {
        return {
            headers: [
                { text: 'Warehouse', value: 'LocationName'},
                { text: 'Address', value: 'addressLine1'},
                { text: 'City', value: 'City.name'},
                { text: 'Tel. Number', value: 'phone_number'},
                { text: 'Status', value: 'status'},
                { text: 'Actions', value: 'actions'}
            ],
            warehouse: {},
            showDelete: false,
            searchQuery: "",
        }
    },
    methods: {
        closeDialog () {
            this.showDelete = false
        },
        showDeleteDialog (item) {
            this.warehouse = item
            this.showDelete = true
        },
        deleteWarehouse (id) {
            this.showDelete = false
            server.delete(`/warehouse/${id}`, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.$store.dispatch('getWarehouses')
                    this.$store.commit('SET_SUCCESS', `Berhasil menghapus Warehouse ${result.data.Warehouse.LocationName}`)
                })
                .catch(err => {
                    this.$store.commit('SET_ERROR', err.response.data.errors[0].message)
                });
        },
        updateStatus(item) {
            this.warehouse = item
            server.put(`/warehouse/${item.id}`, this.warehouse, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.$store.dispatch('getWarehouses')
                    this.$store.commit('SET_SUCCESS', `Updated Warehouse ${result.data.Warehouse.LocationName} status menjadi ${result.data.Warehouse.status ? 'Active' : 'Inactive'}`)
                })
                .catch(err => {
                    this.$store.commit('SET_ERROR', err.response.data.errors[0].message)
                })
        },
        editItem(item) {
            this.item = item
            this.$router.push({ path: `/dashboard/edit/warehouse/${this.item.id}` })
        }
    },
    computed: {
        warehouses () {
            if (this.searchQuery !== "" && this.searchQuery !== null) {
                return this.$store.state.warehouses.filter((product) =>
                product.LocationName.toLowerCase().includes(this.searchQuery)
                );
            } else {
                return this.$store.state.warehouses;
            }
        }
    },
    created () {
        this.$store.dispatch('getWarehouses')
    }
}
</script>

<style>

</style>