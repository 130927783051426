<template>
  <v-app>
    <Navbar v-if="$route.name !== 'Login'" />
    <router-view />
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",

  components: {
    Navbar,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html {
  overflow-y: auto;
}
@font-face {
  font-family: "Averta";
  src: local("Averta"),
    url(./fonts/Averta/AvertaDemoPECuttedDemo-Regular.otf) format("opentype");
}
@font-face {
  font-family: "FuturaLT";
  src: local("FuturaLT"), url(./fonts/FuturaLT/FuturaLT.ttf) format("truetype");
}
* {
  font-family: "FuturaLT";
}
.text--averta {
  font-family: "Averta";
}
.text--futura {
  font-family: "FuturaLT";
}
.text--grey {
  color: #999999;
}
.bg-color-black-1 {
  background-color: #626668 !important;
}
.text--white {
  color: white !important;
}
.background-crystal-color {
  background-color: #e5e5e5;
}
.container-dashboard {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  align-items: flex-start !important;
}
.dark-bg {
  background-color: #363636;
}
.crystal-admin-form {
  background-color: white;
  border-radius: 5px;
}
.admin-page {
  width: 100%;
  margin-top: 100px !important;
}
</style>
