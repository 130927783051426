<template>
    <v-main class="dark-bg fill-height">
        <v-container class="px-4 py-0 fill-height background-crystal-color container-dashboard pb-7" fluid>
            <v-row align="baseline" justify="space-between" class="navbar-admin px-5 pt-5">
                <h4 class="text--averta font-weight-regular">Crystal Of The Sea</h4>
                <div class="d-flex user-details justify-space-between align-center">
                    <v-avatar
                        color="primary"
                        size="36"
                    >
                        <span class="white--text">A</span>
                    </v-avatar>
                    <span>Admin</span>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                            >mdi-chevron-down</v-icon>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn text @click.prevent="logout" class="text-capitalize">
                                        <v-icon small>mdi-logout</v-icon> Logout
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-row>
            <router-view></router-view>
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: 'Dashboard',
    created () {
        if(!localStorage.token) {
            this.$store.commit('SET_LOGIN', false)
            this.$router.push({ name: 'Login' })
        }
    },
    methods: {
        logout () {
            localStorage.clear()
            this.$store.commit('SET_LOGIN', false)
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>

<style>
    .user-details {
        width: 120px;
    }
    .crystal-input {
        font-size: 12px;
        border-radius: 5px;
        background-color: white;
    }
    .navbar-admin {
        position: absolute;
        top: 0;
        width: 100%;
    }
</style>