var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center",attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Promotion List")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"label":"Search Promotion","append-icon":"mdi-magnify","solo":"","clearable":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"2"}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-history")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/dashboard/add/promotion"}},[_c('v-btn',{staticClass:"text--futura text--white text-capitalize",attrs:{"dark":"","block":""}},[_vm._v(" Add Promotion ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"justify-start"},[_c('v-col',{staticClass:"crystal-admin-form",attrs:{"cols":"3"}},[_c('label',{staticClass:"text--futura"},[_vm._v("Status")]),_c('v-select',{attrs:{"items":_vm.items,"item-text":"text","item-value":"value"},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('Alert'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.promotions,"items-per-page":15},scopedSlots:_vm._u([{key:"item.promoStatus",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"click":function($event){return _vm.updateStatus(item)}},model:{value:(item.promoStatus),callback:function ($$v) {_vm.$set(item, "promoStatus", $$v)},expression:"item.promoStatus"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.valid_period",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.valid_from))+" - "+_vm._s(_vm.formatDate(item.valid_until)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('DeleteDialog',{attrs:{"message":("Remove Promotion " + (_vm.promotion.promoName) + " ?"),"item":_vm.promotion,"showDelete":_vm.showDelete},on:{"deleteItem":_vm.deletePromotion,"closeDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }