<template>
  <Faq :faq="faq" @saveFaq="saveFaq" :buttonLoading="buttonLoading" />
</template>

<script>
import server from "../api";
import Faq from "@/components/FaqForm.vue";

export default {
  name: "AddFaq",
  data() {
    return {
      faq: {
        title: "",
        body: "",
        faq_category_id: "",
        locale: ""
      },
      buttonLoading: false,
    };
  },
  components: {
    Faq,
  },
  methods: {
    saveFaq() {
      this.buttonLoading = !this.buttonLoading
      server.post("/faqs", this.faq, {
        headers: {
          token: localStorage.token,
        }
      })
        .then(response => {
          this.$store.commit("SET_SUCCESS", `Success add data faq ${response.data.title}`)
          this.$router.push({ name: "Faq" })
        })
        .catch(err => {
          this.$store.commit("SET_ERROR", err.response.data.errors.message);
        })
        .finally(() => this.buttonLoading = !this.buttonLoading)
    },
  },
};
</script>

<style></style>
