var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Inventory")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"label":"Search Product Name","append-icon":"mdi-magnify","solo":"","clearable":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"2"}},[_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.refreshData()}}},[_c('v-icon',[_vm._v("mdi-history")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/dashboard/add/product"}},[_c('v-btn',{staticClass:"text--futura text--white text-capitalize",attrs:{"dark":"","block":""}},[_vm._v(" Add Product ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"justify-start"},[_c('v-col',{staticClass:"crystal-admin-form",attrs:{"cols":"3"}},[_c('label',{staticClass:"text--futura"},[_vm._v("Status")]),_c('v-select',{attrs:{"items":_vm.items,"item-text":"text","item-value":"value"},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('Alert'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"sort-by":_vm.sortBy,"options":_vm.pagination,"footer-props":{
            'items-per-page-options': _vm.listSize,
        }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:options":_vm.paginate,"update:items-per-page":_vm.getItemPerPage},scopedSlots:_vm._u([{key:"item.gross_price",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatHarga(item.gross_price)))])]}},{key:"item.retail_price",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatHarga(item.retail_price)))])]}},{key:"item.net_weight",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.net_weight ? item.net_weight : 0)+" gr")])]}},{key:"item.gross_weight",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.gross_weight ? item.gross_weight : 0)+" gr")])]}},{key:"item.ItemAssemblyLine.qty",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.countStock(item.ItemAssemblyLines)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)})],1)],1),_c('DeleteDialog',{attrs:{"message":("Remove Item " + (_vm.item.name) + " ?"),"item":_vm.item,"showDelete":_vm.showDelete},on:{"deleteItem":_vm.deleteProduct,"closeDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }