<template>
  <v-dialog
        v-model="showDelete"
        max-width="500px"
        @click:outside="closeDialog"
    >
        <v-card>
            <v-card-text>
            <v-card-title class="font--averta">
                {{ message }}
            </v-card-title>
            <v-form @submit.prevent="deleteItem(item.id)">
                <v-btn
                    class="primary mr-3"
                    type="submit"
                    color="error"
                    rounded
                >
                    Remove
                </v-btn>
                <v-btn
                    class="success"
                    rounded
                    @click.prevent="closeDialog()"
                >
                    Cancel
                </v-btn>
            </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DeleteDialog',
    props: {
        item: Object,
        message: String,
        showDelete: Boolean
    },
    methods: {
        deleteItem (id) {
            this.$emit('deleteItem', id)
        },
        closeDialog () {
            this.$emit('closeDialog')
        }
    }
}
</script>

<style>

</style>