<template>
  <v-container fluid class="admin-page">
    <v-row class="justify-space-between">
      <v-col cols="7">
        <h2>{{ type.text }} Faq Categories</h2>
      </v-col>
      <v-col cols="2">
      </v-col>
    </v-row>
    <v-row justify="center">
      <Alert />
    </v-row>
    <v-row class="justify-end">
      <v-col cols="2">
        <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveFaqCategory()"
          :loading="buttonLoading" :disabled="buttonLoading">
          {{ type.value == "new" ? "Save" : "Update" }}
          <template v-slot:loader>
            <v-progress-circular indeterminate color="black"></v-progress-circular>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="crystal-admin-form justify-space-around pt-5">
      <v-col cols="10">
        <label>Faq Category Name</label>
        <v-text-field solo placeholder="Enter Faq Category Name" v-model="faqCategory.name"></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alert from "@/components/Alert.vue";

export default {
  name: "FaqCategoryForm",
  components: {
    Alert,
  },
  props: {
    faqCategory: {
      type: Object,
      default: () => {
        return {
          name: "",
        };
      },
    },
    type: {
      type: Object,
      default: () => {
        return {
          text: "Add New",
          value: "new",
        };
      },
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveFaqCategory() {
      this.$emit("saveFaqCategory");
    },
  },
};
</script>

<style></style>
