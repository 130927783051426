<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>{{ type.text }} Warehouse</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveWarehouse()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ type.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <Alert />
      <v-row class="crystal-admin-form justify-space-around pt-5">
            <v-col cols="5">
                <label>Warehouse name: </label>
                <v-text-field
                    solo
                    placeholder="Warehouse Name"
                    v-model="warehouse.LocationName"
                ></v-text-field>
                <v-row>
                    <v-col cols="6">
                        <label>Latitude</label>
                        <v-text-field
                            solo
                            placeholder="Enter latitude"
                            v-model="warehouse.latitude"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                         <label>Longitude</label>
                        <v-text-field
                            solo
                            placeholder="Enter longitude"
                            v-model="warehouse.longitude"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <label>Status: </label>
                <v-switch
                    v-model="warehouse.status"
                    color="success"
                    :label="warehouse.status == true ? 'Active' : 'Inactive'"
                ></v-switch>
            </v-col>
            <v-col cols="5">
                <label>Tel. Number: </label>
                <v-text-field
                    solo
                    placeholder="Enter warehouse telephone number"
                    prefix="+62 | "
                    v-model="warehouse.phone_number"
                ></v-text-field>
                <label>Address: </label>
                <v-textarea
                    solo
                    placeholder="Address"
                    v-model="warehouse.addressLine1"
                ></v-textarea>
                <div
                    v-if="type.value == 'new'"
                >
                    <label>Province: </label>
                    <v-autocomplete
                        solo
                        placeholder="Warehouse Province"
                        v-model="warehouseProvince"
                        :items="provinces"
                        item-text="name"
                        item-value="province_id"
                    ></v-autocomplete>
                </div>
                <div
                    v-if="warehouseProvince"
                >
                    <label>City: </label>
                    <v-autocomplete
                        solo
                        placeholder="Warehouse City"
                        v-model="warehouse.city_id"
                        :items="cities"
                        item-text="name"
                        item-value="city_id"
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{ data.item.type }} {{ data.item.name }}
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ data.item.type }} {{ data.item.name }}
                        </template>
                    </v-autocomplete>
                </div>
            </v-col>
      </v-row>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveWarehouse()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ type.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Alert from '@/components/Alert.vue'

export default {
    name: 'WarehouseForm',
    components: {
        Alert
    },
    props: {
        warehouse: {
            type: Object,
            default: () => {
                return {
                    status: true,
                    LocationName: '',
                    latitude: '',
                    longitude: '',
                    addressLine1: '',
                    city_id: '',
                    phone_number: '',
                }
            }
        },
        type: {
            type: Object,
            default: () => {
                return {
                    text: 'Add New',
                    value: 'new'
                }
            }
        },
        buttonLoading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            warehouseProvince: this.warehouse.City ? this.warehouse.City.Province.province_id : ''
        }
    },
    computed: {
        cities () {
            let province_id = this.warehouseProvince
            return this.$store.state.cities.filter(city => city.province_id == province_id)
        },
        provinces () {
            return this.$store.state.provinces
        },
    },
    methods: {
        saveWarehouse() {
            this.$emit('saveWarehouse')
        }
    },
    created () {
        this.$store.dispatch('getCities');
        this.$store.dispatch('getProvinces');
    }
}
</script>

<style>

</style>