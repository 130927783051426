<template>
  <Recipe :recipe="recipe" @saveRecipe="saveRecipe" :buttonLoading="buttonLoading" />
</template>

<script>
import server from "../api";
import Recipe from "@/components/RecipeForm.vue";

export default {
  name: "EditRecipe",
  data() {
    return {
      buttonLoading: false,
    };
  },
  components: {
    Recipe,
  },
  methods: {
    saveRecipe() {
      this.buttonLoading = !this.buttonLoading
      let data = new FormData();
      Object.keys(this.recipe).forEach(key => data.append(key, this.recipe[key]));
      let recipeInfo = this.recipe.recipeInfo
      data.delete("recipeInfo")
      recipeInfo.forEach(element => {
        data.append("recipeInfo", JSON.stringify(element))
      });

      server.put(`/nutrition/recipe/${this.recipe.id}`, data, {
        headers: {
          token: localStorage.token,
        }
      })
        .then(() => {
          this.$store.commit("SET_SUCCESS", `Success edit data recipe`)
          this.$router.push({ name: "Recipe" })
        })
        .catch(err => {
          this.$store.commit("SET_ERROR", err.response.data.errors.message);
        })
        .finally(() => this.d = !this.buttonLoading)
    },
  },
  created () {
    this.$store.dispatch('getRecipeDetail', { slug: this.$route.params.slug });
  },
  computed: {
    recipe() {
      return this.$store.state.detailRecipe
    }
  },
};
</script>

<style></style>
