<template>
  <v-container fluid class="admin-page">
    <v-row class="align-center">
      <v-col cols="8">
        <h2>Consignment Invoice</h2>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Your Order Id"
          append-icon="mdi-magnify"
          solo
          class="shrink"
          clearable
          dense
          v-model="filterOrder"
          @keydown.enter="searchOrder"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="6">
            <div class="pa-4 crystal-admin-form">
              <label>Date</label>
              <v-row>
                  <v-col
                  cols="12"
                  >
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="filterDate"
                          transition="scale-transition"
                          offset-y
                          min-width="350px"
                      >
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="filterDateText"
                              label="Choose Date"
                              readonly
                              v-bind="attrs"
                              append-icon="mdi-calendar"
                              clearable
                              hide-details
                              v-on="on"
                          ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="filterDate"
                              no-title
                              scrollable
                              range
                          >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                          >
                              Cancel
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(filterDate)"
                          >
                              OK
                          </v-btn>
                          </v-date-picker>
                      </v-menu>
                  </v-col>
              </v-row>
          </div>
      </v-col>
      <v-col cols="6">
          <div class="pa-3 crystal-admin-form">
              <label>Customer</label>
              <v-text-field
                  placeholder="Customer"
                  v-model="filterCustomer"
                  @keydown.enter="searchCustomer"
              >
              </v-text-field>
          </div>   
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :loading="tableLoading"
          loading-text="Loading List Order..."
          :items="orders"
          :server-items-length="totalOrders"
          :options="pagination"
          @update:options="paginate"
          :footer-props="{
            'items-per-page-options': listSize,
          }"
          @update:items-per-page="getItemPerPage"
          class="elevation-1"
        >
          <template v-slot:[`item.order_date`]="{ item }">
            <td>{{ formatDate(item.order_date) }}</td>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status == true"
              color="green"
              text-color="white"
            >
              Done
            </v-chip>
            <v-chip
              v-else-if="item.status == false"
              color="yellow"
              text-color="black"
            >
              On Process
            </v-chip>
            <v-chip
              v-else-if="item.status == null"
            >
              Pending
            </v-chip>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <span
              v-for="(product, index) in item.OrderLine.items.slice(0, 2)"
              :key="product.id"
            >
              {{ product.Item ? product.Item.name : ""
              }}{{ ` (x${product.qty})`
              }}{{
                index === 1 && item.OrderLine.items.length - 2 != 0
                  ? `...${item.OrderLine.items.length - 2} more`
                  : index === item.OrderLine.items.length - 1
                  ? ""
                  : ","
              }}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="text--futura text--white text-capitalize"
              dark
              block
              @click="addInvoice(item)"
            >
              Invoice List
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ConsignmentInvoice",
  data() {
    return {
      headers: [
        { text: "ID Order", value: "id" },
        { text: "Date", value: "order_date" },
        { text: "Customer", value: "Customer.fullName" },
        { text: "Product", value: "product" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actions" },
      ],
      pagination: {
        descending: !!this.$route.query.desc,
        itemsPerPage: +this.$route.query.per_page || 15,
        page: +this.$route.query.page || 1,
        customer_type: "stockist",
        totalItems: this.totalOrders,
      },
      listSize: [15, 25, 50, 100, -1],
      filterOrder: this.$route.query.order || "",
      filterDate: [],
      filterCustomer: this.$route.query.customer || '',
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to);
    next();
  },
  watch: {
    filterDate (val) {
      const query = this.$route.query;
      const obj = Object.assign({}, query);
      obj.start_date = val[0] ? val[0] : '';
      obj.end_date = val[1] ? val[1] : '';
      if (!obj.start_date) delete obj.start_date;
      if (!obj.end_date) delete obj.end_date;
      this.$router.replace({
          ...this.$router.currentRoute,
          query: obj
      });
    },
  },
  methods: {
    searchCustomer () {
      const query = this.$route.query;
      const obj = Object.assign({}, query);
      obj.customer = this.filterCustomer;
      if (this.filterCustomer === '') delete obj.customer;
      this.$router.replace({
          ...this.$router.currentRoute,
          query: obj
      });
    },
    searchOrder() {
      const query = this.$route.query;
      const obj = Object.assign({}, query);
      obj.order = this.filterOrder;
      if (this.filterOrder === "") delete obj.order;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: obj,
      });
    },
    getItemPerPage(val) {
      const query = this.$route.query;
      const obj = Object.assign({}, query);
      obj.per_page = +val;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: obj,
      });
    },
    addInvoice(item) {
      this.$router.push({
        path: `/dashboard/add/invoice/?id_order=${item.id}`,
      });
    },
    fetchData(route) {
      this.$store.dispatch("getOrders", route.query);
    },
    paginate(val) {
      // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
      const query = this.$route.query;
      const obj = Object.assign({}, query);
      if (val.itemsPerPage !== this.listSize[0])
        obj.per_page = val.itemsPerPage;
      if (val.descending) obj.desc = "true";
      else delete obj.desc;
      obj.orderby = val.sortBy;
      obj.page = val.page;
      obj.per_page = val.itemsPerPage;
      obj.customer_type = "stockist";
      // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
      let same = true;
      for (let key in query) {
        if (query[key] != obj[key]) {
          same = false;
          break;
        }
      }
      // to handle the case when a KEY exists in OBJ but not in query
      for (let key in obj) {
        if (query[key] != obj[key]) {
          same = false;
          break;
        }
      }
      if (same) this.fetchData();
      // page has been manually reloaded in the browser
      else {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: obj,
        });
      }
    },
  },
  computed: {
    
    filterDateText: {
      get () {
          return this.filterDate.join(' - ');
      },
      set () {
          this.filterDate = [];
      }
    },
    orders() {
      return this.$store.state.orders;
    },
    totalOrders() {
      return this.$store.state.totalOrders;
    },
    tableLoading() {
      return this.$store.state.tableLoading;
    },
  },
  created() {
    if(this.$route.query.customer_type === undefined) {
      this.$store.dispatch("getOrders", {customer_type:"stockist"});
    } else {
      this.$store.dispatch("getOrders", this.$route.query);
    }
  },
};
</script>

<style></style>
