<template>
  <WarehouseForm 
    :warehouse="warehouse"
    @saveWarehouse="updateWarehouse"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import WarehouseForm from '@/components/WarehouseForm';
import server from '../api';

export default {
    name: 'EditWarehouse',
    data () {
        return {
            buttonLoading: false
        }
    },
    components: {
        WarehouseForm
    },
    computed: {
        warehouse () {
            return this.$store.state.warehouse
        }
    },
    methods: {
        updateWarehouse () {
            this.buttonLoading = !this.buttonLoading;
            server.put(`/warehouse/${this.warehouse.id}`, this.warehouse, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Memperbarui Warehouse ${result.data.Warehouse.LocationName}`);
                    this.$router.push({ name: 'Warehouse' });
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    if(err.response.data.errors.length) {
                        this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                    } else {
                        this.$store.commit('SET_ERROR', err.response.data.errors.message);
                    }
                })
        }
    },
    created () {
        this.$store.dispatch('getWarehouseById', { id: this.$route.params.id })
    }
}
</script>

<style>

</style>