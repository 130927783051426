<template>
  <v-dialog
    v-model="showBillDialog"
    max-width="700px"
  >
      <v-card>
          <v-card-title class="bg-grey">
              Apply {{ type }}
            <v-spacer></v-spacer>
            <v-btn
                    icon
                    @click.prevent="closeModal()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
          </v-card-title>
          <v-container>
            <v-row class="justify-center bg-grey">
                <v-col cols="6">
                    <v-row>
                        <v-col cols="6">
                            <p>Vendor</p>
                            <p>Ref. No</p>
                            <p>Date</p>
                            <p>Original Amt.</p>
                        </v-col>
                        <v-col cols="6">
                            <p>{{ bill.name }}</p>
                            <p>{{ bill.ref_no }}</p>
                            <p>{{ bill.disc_date }}</p>
                            <p>{{ formatHarga(bill.amt_due) }}</p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row>
                        <v-col cols="6">
                            <p>Amount</p>
                            <p>Disc. Used</p>
                            <p>Credit Used</p>
                            <p>Amt. to pay</p>
                        </v-col>
                        <v-col cols="6">
                            <p>{{ formatHarga(bill.amt_due) }}</p>
                            <p>{{ formatHarga(bill.disc_used) }}</p>
                            <p>{{ formatHarga(bill.credit_used) }}</p>
                            <p>{{ formatHarga(bill.pay_amount) }}</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
          </v-container>
          <v-container>
              <v-row class="bg-grey">
                  <slot name="bill-dialog-content">
                      <v-col cols="3">
                          <p>Discount Date</p>
                          <p>Terms</p>
                          <p>Suggested Discount</p><br>
                          <p>Amount of disc</p>
                          <p>Disc Class</p>
                      </v-col>
                      <v-col cols="9">
                          <p>{{ bill.disc_date }}</p>
                          <p>Due on receipt</p>
                          <p>{{ formatHarga(bill.pay_amount) }}</p>
                          <v-row class="align-center justify-space-between">
                            <v-col cols="4">
                                <v-text-field
                                    placeholder="Enter amount"
                                    dense
                                    solo
                                    hide-details="auto"
                                >
                                </v-text-field>
                            </v-col>
                            <span>Disc Account</span>
                            <v-col cols="4">
                                <v-select
                                    label="Amount"
                                    dense
                                    solo
                                    hide-details="auto"
                                >
                                </v-select>
                            </v-col>
                          </v-row>
                          <v-select
                                label="Amount"
                                dense
                                solo
                                hide-details="auto"
                            >
                            </v-select>
                      </v-col>
                  </slot>
              </v-row>
          </v-container>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                light
                dense
                class="text-capitalize"
                @click.prevent="closeModal()"
              >
                Cancel
              </v-btn>
              <v-btn
                dark
                dense
                class="text--white text-capitalize"
                >
                Done
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'BillDialog',
    props: {
        showBillDialog: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'Discount'
        },
        bill: {
            type: Object
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
    }
}
</script>

<style scoped>
    .bg-grey {
        background-color: #F5F5F5;
    }
    .v-text-field {
        width: 125px;
    }

</style>