<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>{{ formType.text }} Order</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveOrder()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ formType.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <v-container fluid class="crystal-admin-form">
        <v-row class="justify-space-around">
                <v-col cols="5">
                    <label>Customer Name: </label>
                    <v-text-field
                        solo
                        placeholder="Enter Customer name"
                        v-model="order.Customer.fullName"
                    ></v-text-field>
                    <label>Customer Contact</label>
                    <v-text-field
                        solo
                        placeholder="Enter Customer Phone Number"
                        prefix="+62 |"
                        v-model="orderContact"
                    ></v-text-field>
                    <label>Shipment Address: </label>
                    <v-textarea
                        solo
                        placeholder="Shipment Address"
                        v-model="order.ShiptoAddress"
                    ></v-textarea>
                    <label>Shipment Province: </label>
                    <v-autocomplete
                        solo
                        placeholder="Shipment Province"
                        v-model="order.province_id"
                        :items="provinces"
                        item-text="name"
                        item-value="province_id"
                    ></v-autocomplete>
                    <div
                        v-if="order.province_id"
                    >
                        <label>Shipment City: </label>
                        <v-autocomplete
                            solo
                            placeholder="Shipment City"
                            v-model="order.city_id"
                            :items="cities"
                            item-text="name"
                            item-value="city_id"
                        >
                            <template slot="selection" slot-scope="data">
                                <!-- HTML that describe how select should render selected items -->
                                {{ data.item.type }} {{ data.item.name }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                {{ data.item.type }} {{ data.item.name }}
                            </template>
                        </v-autocomplete>
                    </div>
                    <label>Postal Code:</label>
                    <v-text-field
                        solo
                        placeholder="Enter Postal Code"
                        v-model="order.postal_code"
                    ></v-text-field>
                    <label>Shipment Status: </label>
                    <v-select
                        solo
                        :items="shipment_statuses"
                        item-text="label"
                        item-value="value"
                        label="Choose Status"
                        v-model="order.ShipStatus"
                    ></v-select>
                    <label>Payment Status: </label>
                    <v-select
                        solo
                        :items="order_statuses"
                        label="Choose Status"
                        v-model="order.OrderStatus"
                    ></v-select>
                    <!-- <label>Courier Tracking Code: </label>
                    <v-text-field
                        solo
                        placeholder="Courier Tracking Code"
                    ></v-text-field> -->
                    <!-- <label>AR Account: </label>
                    <v-text-field
                        solo
                        placeholder="Enter AR Account"
                    ></v-text-field> -->
                    <v-row class="justify-space-between align-center">
                        <v-col cols="3">
                            <label>Dropshipper</label>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox
                                v-model="dropshipper_check"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <div
                        v-if="dropshipper_check"
                    >
                        <label>Dropshipper Name:</label>
                        <v-text-field 
                            solo
                            placeholder="Enter Name"
                        >
                        </v-text-field>
                        <label>Dropshipper Contact:</label>
                        <v-text-field 
                            solo
                            placeholder="Enter phone number"
                            prefix="+62 |"
                        >
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="5">
                    <label>Order Date: </label>
                    <v-row>
                        <v-col
                        cols="12"
                        >
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="order_date"
                                transition="scale-transition"
                                offset-y
                                min-width="350px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="order_date"
                                    label="Order Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    solo
                                    append-icon="mdi-calendar"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="order_date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(order_date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <label>Ship From</label>
                    <v-autocomplete
                        solo
                        :items="warehouses"
                        placeholder="Warehouse Location"
                        append-icon="mdi-magnify"
                        item-text="LocationName"
                        item-value="id"
                        v-model="order.ShipFromLocationId"
                    >    
                    </v-autocomplete>
                    <label>Shipment Date: </label>
                    <v-row>
                        <v-col
                        cols="12"
                        >
                            <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :return-value.sync="shipment_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="shipment_date"
                                    label="Shipment Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    solo
                                    append-icon="mdi-calendar"
                                    clearable
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="shipment_date"
                                    no-title
                                    scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu2 = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu2.save(shipment_date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <label>Sales Type:</label>
                    <v-select
                        :items="sales_types"
                        solo
                        label="Choose Type"
                        v-model="order.sales_type"
                    >
                    </v-select>
                    <label>Customer Type:</label>
                    <v-select
                        :items="customer_types"
                        solo
                        label="Choose Type"
                        v-model="order.customer_type"
                    >
                    </v-select>
                    <label>Courier:</label>
                    <v-select
                        :items="couriers"
                        solo
                        label="Choose Couriers"
                        item-text="CourierCompany"
                        item-value="id"
                        v-model="order.DeliveryMethodId"
                    >
                    </v-select>
                    <label>External Invoice Number:</label>
                    <v-text-field
                        solo
                        placeholder="Enter Invoice Number"
                        v-model="order.invoice_no"
                    >
                    </v-text-field>
                    <label>Memo:</label>
                    <v-text-field
                        solo
                        placeholder="Enter Memo"
                        v-model="order.memo"
                    >
                    </v-text-field>
                </v-col>
        </v-row>
        <hr>
        <v-container>
            <ProductOrder
                v-for="(product, index) in order.OrderLine.items"
                :key="product.id"
                :index="index"
                :product="product"
                :formType="formType"
                @deleteProduct="deleteProduct"
                @updateProduct="updateProduct"
            />
        </v-container>
        <v-row class="justify-center">
            <v-col cols="2">
                <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="addProduct()">
                    + Add Product
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <v-row class="flex-column align-end">
            <v-col cols="4">
                <span>Total Quantity : {{ quantity }}</span>
            </v-col>
            <v-col cols="4">
                <span>Sub Total : {{ formatHarga(price_total) }}</span>
            </v-col>
            <v-col cols="4">
                <span>Tax <v-select v-model="order.tax" dense :items="taxes" item-value="value" item-text="text" autowidth></v-select> : {{ tax }}</span>
            </v-col>
            <v-col cols="4">
                <span>Delivery Fee : <v-text-field v-model="order.delivery_fee"></v-text-field> </span>
            </v-col>
            <v-col cols="4">
                <span><b>Total : {{ formatHarga(grand_total) }}</b></span>
            </v-col>
        </v-row>
      </v-container>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveOrder()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ formType.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import ProductOrder from '@/components/ProductOrder.vue'

export default {
    name: 'OrderForm',
    components: {
        ProductOrder
    },
    props: {
        formType: {
            type: Object,
            default: () => {
                return {
                    text: 'Add New',
                    value: 'new'
                }
            }
        },
        buttonLoading: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object,
            default: () => {
                return {
                    city_id: '',
                    province_id: '',
                    ShiptoAddress: '',
                    ShipStatus: 'Processed',
                    OrderStatus: 'payment_pending',
                    ShipFromLocationId: '',
                    order_date: new Date().toISOString().substr(0, 10),
                    shipment_date: new Date().toISOString().substr(0, 10),
                    DeliveryMethodId: 1,
                    sales_type: '',
                    customer_type: '',
                    Customer: {
                        fullName: '',
                        CustomerContact: {
                            contactNo: ''
                        }
                    },
                    OrderLine: {
                        items: []
                    },
                    tax: 0,
                    delivery_fee: 0,
                    memo: '',
                    invoice_no: ''
                }
            }
        },
    },
    data () {
        return {
            taxes: [
                { text: '0%', value: 0 },
                { text: '5 %', value: 5},
                { text: '11 %', value: 11},
                { text: '20 %', value: 20},
                { text: '30 %', value: 30}
            ],
            dropshipper_check: false,
            menu: false,
            menu2: false,
            selectedTax: 10,
        }
    },
    methods: {
        saveOrder() {
            this.$emit('saveOrder', this.grand_total)
        },
        addProduct () {
            let newProduct = {
                ItemId: '',
                qty: '',
                price: '',
                Item: {
                    name: ''
                }
            }
            this.order.OrderLine.items.push(newProduct)
        },
        deleteProduct(index) {
            this.order.OrderLine.items.splice(index, 1)
        },
        updateProduct (index, id, qty, price) {
            this.order.OrderLine.items[index].ItemId = id
            this.order.OrderLine.items[index].qty = qty
            this.order.OrderLine.items[index].price = price
        },
    },
    computed: {
        order_statuses () {
            return this.$store.state.order_statuses;
        },
        sales_types () {
            return this.$store.state.sales_types;
        },
        shipment_statuses () {
            return this.$store.state.shipment_statuses;
        },
        orderContact: {
            get () {
                return this.removeCountryCode(this.order.Customer.CustomerContact.contactNo);
            },
            set (value) {
                this.order.Customer.CustomerContact.contactNo = value;
            }
        },
        cities () {
            let province_id = this.order.province_id
            return this.$store.state.cities.filter(city => city.province_id == province_id)
        },
        quantity () {
            let quantity = 0;
            for(let i = 0; i < this.order.OrderLine.items.length; i++) {
                quantity += +this.order.OrderLine.items[i].qty
            }
            return quantity
        },
        products () {
            return this.$store.state.products
        },
        provinces () {
            return this.$store.state.provinces
        },
        tax () {
            return ((this.order.tax * 0.01) * this.price_total)
        },
        price_total () {
            let count = 0;
            let price_total = 0;
            for(let i = 0; i < this.order.OrderLine.items.length; i++) {
                let item_price = this.order.OrderLine.items[i].price ? this.order.OrderLine.items[i].price : this.order.OrderLine.items[i].Item.retail_price;
                count = this.order.OrderLine.items[i].qty * item_price;
                price_total += count
                count = 0
            }
            return price_total
        },
        grand_total () {
            return this.price_total + this.tax + (+this.order.delivery_fee)
        },
        couriers () {
            return this.$store.state.couriers
        },
        customer_types () {
            return this.$store.state.customer_types
        },
        warehouses () {
            return this.$store.state.warehouses
        },
        shipment_date: {
            get: function () {
                return this.order.shipment_date ? this.formatDate(this.order.shipment_date, 'YYYY-MM-DD') : '';
            },
            set: function (value) {
                this.order.shipment_date = value
            }
        },
        order_date: {
            get: function () {
                return this.order.order_date ? this.formatDate(this.order.order_date, 'YYYY-MM-DD') : this.formatDate(new Date(), 'YYYY-MM-DD');
            },
            set: function (value) {
                this.order.order_date = value
            }
        }
    },
    created () {
        this.$store.dispatch('getProducts')
        this.$store.dispatch('getProvinces')
        this.$store.dispatch('getCities')
        this.$store.dispatch('getCouriers')
        this.$store.dispatch('getWarehouses')
    }
}
</script>

<style>

</style>