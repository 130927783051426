<template>
  <v-row class="justify-space-around align-center">
      <v-col cols="5">
            <label>Product Name: </label>
                <v-text-field
                    v-model="product.Item.name"
                    solo
                    placeholder="Product name"
                    readonly
                ></v-text-field>
        </v-col>
        <v-col cols="5">
            <v-row class="align-center">
                <v-col cols="8">
                    <label>Quantity</label>
                    <v-text-field
                        v-model="product.qty"
                        solo
                        placeholder="Enter Product Quantity"
                        prefix="Qty |"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-btn
                        fab
                        dark
                        small
                        @click.prevent="deleteProduct(index)"
                    >
                        <v-icon dark small>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
  </v-row>
</template>

<script>
export default {
    name: 'ReturnOrder',
    props: [ 'product', 'index' ],
    computed: {
    },
    methods: {
        deleteProduct(index) {
            this.$emit('deleteProduct', index)
        }
    }
}
</script>

<style>

</style>