<template>
  <SampleForm 
    :sample="sample"
    @saveSample="updateSample"
    :formType="formType"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import SampleForm from '@/components/SampleForm.vue';
import server from '../api';

export default {
    name: 'EditSample',
    components: {
        SampleForm
    },
    data () {
        return {
            formType: {
                text: 'Edit',
                value: 'edit'
            },
            buttonLoading: false
        }
    },
    computed: {
        sample () {
            return this.$store.state.sample;
        }
    },
    methods: {
        updateSample (recipient) {
            server.put(`samples/${this.sample.id}`, {
                CustomerId: recipient.CustomerContact ? recipient.id : null,
                ShipFromLocationId: this.sample.ShipFromLocationId,
                DeliveryMethodId: this.sample.DeliveryMethodId,
                ShiptoAddress1: this.sample.ShiptoAddress1,
                purpose: this.sample.purpose,
                items: this.sample.SampleLine.items,
                shipment_date: this.sample.shipment_date,
                status: this.sample.ShipStatus,
                ShipStatus: this.sample.ShipStatus,
                VendorId: recipient.VendorContact ? recipient.id : null,
                province_id: this.sample.province_id,
                city_id: this.sample.city_id,
                postal_code: this.sample.postal_code,
                memo: this.sample.memo
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(() => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `berhasil mengubah Sample`);
                    this.$router.go(-1);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', err.response.data.errors.message);
                });
        }
    },
    created () {
        this.$store.dispatch('getSampleById', { id: this.$route.params.id });
    }
}
</script>

<style>

</style>