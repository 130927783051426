<template>
  <ProductForm
    :item="item"
    @saveProduct="saveProduct"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import server from "../api";
import ProductForm from "@/components/ProductForm.vue";
import moment, { now } from "moment";
export default {
  name: "AddProduct",
  components: {
    ProductForm,
  },
  data() {
    return {
      item: {
        status: 0,
        name: "",
        ecommerce_name: "",
        whatsapp_name: "",
        description: "",
        gross_weight: "",
        net_weight: "",
        retail_price: 0,
        resaller_price: 0,
        wholesaler_price: 0,
        cost_price: 0,
        product_photo: "",
        preview1: null,
        sku: "",
        product_type: "single",
        bundle_items: [],
        bpom_md: "",
        barcode: "",
        category_id: "",
        slug: "",
        meta_title: "",
        meta_keywords: "",
        meta_description: "",
       faqs: [],
        content_box: "",
        schedule_time: "",
        gross_price: 0,
        discount_product: 0,
        discount: 0,
        tag_script: "",
        image_alt: "",
        image_url: "",
        sold_product: 0,
        photos: [],
        video: null,
        createdAt: now,
        updatedAt: now,
        ItemAssemblyLines: [],
        ItemCatalogs: [],
        ItemFaqs: [],
        item_category: [],
        ///
      },
      buttonLoading: false,
    };
  },
  methods: {
    saveProduct(items) {
      this.buttonLoading = !this.buttonLoading;
      let data = new FormData();

      data.append("status", this.item.status);
      data.append("name", this.item.name);
      data.append("ecommerce_name", this.item.ecommerce_name);
      data.append("whatsapp_name", this.item.whatsapp_name);
      data.append("description", this.item.description);
      data.append("gross_weight", this.item.gross_weight);
      data.append("net_weight", this.item.net_weight);
      data.append("retail_price", this.item.retail_price);
      data.append("resaller_price", this.item.resaller_price);
      data.append("wholesaler_price", this.item.wholesaler_price);
      data.append("cost_price", this.item.cost_price);
      data.append("bundle_items", JSON.stringify(items));
      data.append("sku", this.item.sku);
      data.append("bpom_md", this.item.bpom_md);
      data.append("barcode", this.item.barcode);
      data.append("category_id", this.item.category_id);
      data.append("slug", this.item.slug);
      data.append("meta_title", this.item.meta_title);
      data.append("meta_keywords", this.item.meta_keywords);
      data.append("meta_description", this.item.meta_description);
      this.item.faqs.filter(faq => faq.locale == "id_ID").forEach(element => data.append("faqs", JSON.stringify(element)));
      data.append("content_box", this.item.content_box);
      data.append("gross_price", typeof this.item.gross_price != "number" ? parseInt(this.item.gross_price) : this.item.gross_price);
      data.append("discount_product", typeof this.item.discount_product != "number" ? parseFloat(this.item.discount_product) : this.item.discount_product);
      data.append("tag_script", this.item.tag_script);
      this.item.photos.forEach((element, i) => data.append(`image${i}`, element));
      data.append("video3", this.item.video);
      data.append(
        "schedule_time",
        this.item.schedule_time
          ? moment(this.item.schedule_time).toISOString()
          : ""
      );

      server
        .post("/products", data, {
          headers: {
            token: localStorage.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.buttonLoading = !this.buttonLoading;
          this.$store.commit(
            "SET_SUCCESS",
            `Berhasil Menambahkan Product ${res.data.Product.name}`
          );
          this.$router.push({ name: "Inventory" });
        })
        .catch((err) => {
          this.buttonLoading = !this.buttonLoading;
          this.$store.commit("SET_ERROR", err.response.data.errors.message);
        });
    },
  },
  created() {
    this.$store.commit("SET_SUCCESS", "");
  },
};
</script>

<style>
.btn-upload {
  border-radius: 5px;
  height: 150px;
  width: 150px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.product-preview {
  max-width: 100%;
  max-height: 100%;
}
</style>
