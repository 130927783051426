<template>
  <VendorForm
    :vendor="vendor"
    @saveVendor="updateVendor"
    :formType="formType"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import VendorForm from '@/components/VendorForm.vue';
import server from '../api';

export default {
    name: 'EditVendor',
    components: {
        VendorForm
    },
    data () {
        return {
            formType: {
                text: 'Edit',
                value: 'edit'
            },
            buttonLoading: false
        }
    },
    computed: {
        vendor () {
            return this.$store.state.vendor
        }
    },
    methods: {
        updateVendor() {
            this.buttonLoading = !this.buttonLoading;
            server.put(`/vendors/${this.vendor.id}`, this.vendor, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Memperbarui Vendor ${result.data.Vendor.company_name}`);
                    this.$router.go(-1);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    if(err.response.data.errors.length) {
                        this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                    } else {
                        this.$store.commit('SET_ERROR', err.response.data.errors.message);
                    }
                });
        }
    },
    created() {
        this.$store.dispatch('getVendorById', { id: this.$route.params.id });
    }
}
</script>

<style>

</style>