<template>
  <CourierForm 
    :courier="courier"
    @saveCourier="updateCourier"
    :type="type"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import CourierForm from '@/components/CourierForm.vue';
import server from '../api';

export default {
    name: 'EditCourier',
    data () {
        return {
            type: {
                text: 'Edit',
                value: 'edit'
            }
        }
    },
    components: {
        CourierForm
    },
    methods: {
        updateCourier () {
            this.buttonLoading = !this.buttonLoading;
            server.put(`/delivery/fees/${this.courier.id}`, this.courier, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Memperbarui Courier ${result.data.Delivery.code}`);
                    this.$router.push({ name: 'Delivery' });
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    if(err.response.data.errors.length) {
                        this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                    } else {
                        this.$store.commit('SET_ERROR', err.response.data.errors.message);
                    }
                });
        }
    },
    computed: {
        courier () {
            return this.$store.state.courier
        }
    },
    created () {
        this.$store.dispatch('getCourierById', { id: this.$route.params.id })
    }
}
</script>

<style>

</style>