<template>
  <v-container fluid class="admin-page">
    <v-row>
      <v-col cols="6">
        <h2>Newsletter Subscribers</h2>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="4" offset="7">
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-data-table :loading="tableLoading" :headers="headers" :items="newsletterSubscriber" :items-per-page="5" class="elevation-1">          
          <template v-slot:[`item.createdAt`]="{ item }">
                    {{ formatDate(item.createdAt) }}
                </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "NewsletterSubscriber",
  data() {
    return {
      headers: [
      { text: "Email", value: "email" },
      { text: "Created At", value: "createdAt" },
      ],
    };
  },
  computed: {
    newsletterSubscriber() {
      console.log(this.$store.state.newsletterSubscriber)
      return this.$store.state.newsletterSubscriber;
    },
    tableLoading() {
      return this.$store.state.tableLoading;
    },
  },
  created() {
    this.$store.dispatch("getNewsletterSubscribers");
  },
};
</script>