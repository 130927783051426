<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>{{ formType.text }} Sample</h2>
        </v-col>
        <v-col cols="2" class="text-center">
            <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveSample()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ formType.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <Alert />
      <v-container class="crystal-admin-form">
        <v-row class="justify-space-around">
                <v-col cols="5">
                    <label>Recipient Type: </label>
                    <v-select
                        solo
                        placeholder="Recipient Type"
                        v-model="recipient_type"
                        :items="recipient_types"
                    >
                    </v-select>
                    <label>Recipient Name: </label>
                    <v-autocomplete
                        solo
                        placeholder="Search Recipient"
                        v-model="recipient"
                        :items="select_customers"
                        item-text="fullName"
                        return-object
                        v-if="recipient_type == 'customer' || recipient_type == ''"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                        solo
                        placeholder="Search Recipient"
                        v-model="recipient"
                        :items="select_vendors"
                        v-else-if="recipient_type == 'vendor'"
                        item-text="company_name"
                        return-object
                    >
                    </v-autocomplete>
                    <label>Customer Contact</label>
                    <v-text-field
                        solo
                        placeholder="Enter Recipient Phone Number"
                        prefix="+62 |"
                        v-model="phone_number"
                    ></v-text-field>
                    <label>Shipment Address: </label>
                    <v-textarea
                        solo
                        placeholder="Shipment Address"
                        v-model="sample.ShiptoAddress1"
                    ></v-textarea>
                    <label>Shipment Province: </label>
                    <v-autocomplete
                        solo
                        placeholder="Shipment Province"
                        v-model="sample.province_id"
                        :items="provinces"
                        item-text="name"
                        item-value="province_id"
                    ></v-autocomplete>
                    <div
                        v-if="sample.province_id"
                    >
                        <label>Shipment City: </label>
                        <v-autocomplete
                            solo
                            placeholder="Shipment City"
                            v-model="sample.city_id"
                            :items="cities"
                            item-text="name"
                            item-value="city_id"
                        >
                            <template slot="selection" slot-scope="data">
                                <!-- HTML that describe how select should render selected items -->
                                {{ data.item.type }} {{ data.item.name }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                {{ data.item.type }} {{ data.item.name }}
                            </template>
                        </v-autocomplete>
                    </div>
                    <label>Postal Code:</label>
                    <v-text-field
                        solo
                        placeholder="Enter Postal Code"
                        v-model="sample.postal_code"
                    >
                    </v-text-field>
                    <label>Shipment Status: </label>
                    <v-select
                        solo
                        :items="shipment_statuses"
                        item-text="label" 
                        item-value="value"
                        label="Choose Status"
                        v-model="sample.ShipStatus"
                    ></v-select>
                    <!-- <label>Courier Tracking Code: </label>
                    <v-text-field
                        solo
                        placeholder="Courier Tracking Code"
                    ></v-text-field> -->
                </v-col>
                <v-col cols="5">
                    <label>Ship From</label>
                    <v-autocomplete
                        solo
                        :items="warehouses"
                        placeholder="Warehouse Location"
                        append-icon="mdi-magnify"
                        item-text="LocationName"
                        item-value="id"
                        v-model="sample.ShipFromLocationId"
                    >    
                    </v-autocomplete>
                    <label>Shipment Date: </label>
                    <v-row>
                        <v-col
                        cols="12"
                        >
                            <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :return-value.sync="shipment_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="shipment_date"
                                    label="Shipment Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    solo
                                    append-icon="mdi-calendar"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="shipment_date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu2 = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu2.save(shipment_date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <label>Purpose:</label>
                    <v-select
                        :items="purposes"
                        solo
                        label="Choose Purpose"
                        v-model="sample.purpose"
                    >
                    </v-select>
                    <label>Courier:</label>
                    <v-select
                        solo
                        :items="couriers"
                        item-text="CourierCompany"
                        item-value="id"
                        v-model="sample.DeliveryMethodId"
                        label="Choose Couriers"
                    >
                    </v-select>
                    <label>Memo:</label>
                    <v-text-field
                        solo
                        placeholder="Enter Memo"
                        v-model="sample.memo"
                    >
                    </v-text-field>
                </v-col>
        </v-row>
        <hr>
        <v-container>
            <ProductOrder
                v-for="(product, index) in sample.SampleLine.items"
                :key="product.id"
                :index="index"
                :product="product"
                @deleteProduct="deleteProduct"
                @updateProduct="updateProduct"
                type="sample"
            />
        </v-container>
        <v-row class="justify-center">
            <v-col cols="2">
                <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="addProduct()">
                    + Add Product
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <v-row class="flex-column align-end">
            <v-col cols="4">
                <span>Total Quantity : {{ quantity }}</span>
            </v-col>
        </v-row>
      </v-container>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveSample()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ formType.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Alert from '@/components/Alert.vue';
import ProductOrder from '@/components/ProductOrder.vue';

export default {
    name: 'SampleForm',
    components: {
        Alert,
        ProductOrder,
    },
    props: {
        sample: {
            type: Object,
            default: () => {
                return {
                    DeliveryMethodId: 1,
                    ShipStatus: '',
                    province_id: '',
                    city_id: '',
                    ShiptoAddress1: '',
                    purpose: '',
                    ShipFromLocationId: '',
                    SampleLine: {
                        items: []
                    },
                    shipment_date: '',
                    memo: ''
                }
            }
        },
        formType: {
            type: Object,
            default: () => {
                return {
                    text: 'Add New',
                    value: 'new'
                }
            }
        },
        buttonLoading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            menu: false,
            menu2: false,
            taxes: [
                { text: '5 %', value: 5},
                { text: '10 %', value: 10},
                { text: '20 %', value: 20},
                { text: '30 %', value: 30}
            ],
            dropshipper_check: true,
            recipient_types: [
                {
                    text: 'Vendor',
                    value: 'vendor'
                },
                {
                    text: 'Customer',
                    value: 'customer'
                }
            ],
            selectedTax: 10,
            delivery_fee: 0,
            recipientType: '',
            recipientObj: {}
        }
    },
    methods: {
        saveSample () {
            this.$emit('saveSample', this.recipient);
        },
        addProduct () {
            let newProduct = {
                ItemId: '',
                qty: '',
                Item: {
                    name: ''
                }
            }
            this.sample.SampleLine.items.push(newProduct);
        },
        deleteProduct(index) {
            this.sample.SampleLine.items.splice(index, 1);
        },
        updateProduct (index, id, qty, price) {
            this.sample.SampleLine.items[index].ItemId = id;
            this.sample.SampleLine.items[index].qty = qty;
            this.sample.SampleLine.items[index].price = price;
        }
    },
    computed: {
        quantity () {
            let quantity = 0;
            for(let i = 0; i < this.sample.SampleLine.items.length; i++) {
                quantity += +this.sample.SampleLine.items[i].qty;
            }
            return quantity;
        },
        select_customers () {
            return this.$store.state.select_customers;
        },
        provinces () {
            return this.$store.state.provinces;
        },
        cities () {
            let province_id = this.sample.province_id;
            return this.$store.state.cities.filter(city => city.province_id == province_id)
        },
        couriers () {
            return this.$store.state.couriers;
        },
        customer_types () {
            return this.$store.state.customer_types;
        },
        warehouses () {
            return this.$store.state.warehouses;
        },
        select_vendors () {
            return this.$store.state.select_vendors;
        },
        purposes () {
            return this.$store.state.purposes;
        },
        shipment_statuses () {
            return this.$store.state.shipment_statuses;
        },
        recipient: {
            get: function () {
                if (this.sample.Customer) {
                    return this.sample.Customer
                } else if (this.sample.Vendor) {
                   return this.sample.Vendor
                } else {
                    return this.recipientObj
                }
            },
            set: function (value) {
                this.recipientObj = value;
            }
        },
        recipient_type: {
            get: function () {
                if (this.sample.Customer) {
                    return 'customer'
                } else if (this.sample.Vendor) {
                    return 'vendor'
                } else {
                    return this.recipientType
                }
            },
            set: function (value) {
                this.recipientType = value;
            }
        },
        phone_number: {
            get: function () {
                if(this.recipient.CustomerContact) {
                    return this.removeCountryCode(this.recipient.CustomerContact.contactNo);
                } else if(this.recipient.VendorContact) { 
                    return this.removeCountryCode(this.recipient.VendorContact.phone_number);
                } else {
                    return ''
                }
            },
        },
        shipment_date: {
            get: function () {
                return this.sample.shipment_date ? this.formatDate(this.sample.shipment_date, 'YYYY-MM-DD') : ''
            },
            set: function (value) {
                this.sample.shipment_date = value
            }
        }
    },
    created () {
        this.$store.dispatch('getCustomersLite')
        this.$store.dispatch('getProvinces')
        this.$store.dispatch('getCities')
        this.$store.dispatch('getCouriers')
        this.$store.dispatch('getWarehouses')
        this.$store.dispatch('getVendorsLite')
    }
}
</script>

<style>

</style>