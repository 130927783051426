<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="7"
        >
            <h2>Checks</h2>
        </v-col>
        <v-col
        cols="5">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Order"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/addcheck">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Create Check
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="2" class="crystal-admin-form">       
            <label>Product</label>
            <v-text-field
                placeholder="Product"
            />
        </v-col>
        <v-col cols="2" class="crystal-admin-form">       
            <label>Post Date</label>
            <v-row>
                <v-col cols="5">
                    <v-text-field
                        placeholder="Start"
                    />
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        placeholder="End"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="2" class="crystal-admin-form">       
            <label>Total Fees</label>
            <v-row>
                <v-col cols="5">
                    <v-text-field
                        placeholder="min"
                    />
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        placeholder="max"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="2" class="crystal-admin-form">       
            <label>Fees Paid</label>
            <v-row>
                <v-col cols="5">
                    <v-text-field
                        placeholder="min"
                    />
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        placeholder="max"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="2" class="crystal-admin-form">
            <label>Status</label>    
            <v-select
                label="Active or Inactive"
            >
            </v-select>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <v-data-table
                :headers="headers"
                :items="vendors"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:[`item.amount`]="{item}">
                    {{ formatHarga(item.amount) }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <span :class="checkStatus(item.status) ? 'red--text' : 'black--text'">{{ item.status }}</span>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
    name: 'Check',
    data () {
        return {
            detail_order: false,
            selectedOrder: {},
            headers: [
                { text: 'Date', value: 'date'},
                { text: 'Pay Order', value: 'pay_order'},
                { text: 'Check No', value: 'check_no'},
                { text: 'Memo', value: 'memo'},
                { text: 'Class', value: 'class' },
                { text: 'Amount', value: 'amount'},
                { text: 'Status', value: 'status'},
            ],
            vendors: [
                { 
                    id: 1,
                    date: '05/10/2020',
                    pay_order: 'Antonio Kendrick',
                    check_no: '09978787',
                    memo: 'Lorem Ipsum',
                    class: 'Lorem Ipsum',
                    amount: '100000',
                    status: 'Draft'
                },
                { 
                    id: 2,
                    date: '05/10/2020',
                    pay_order: 'Antonio Kendrick',
                    check_no: '09978787',
                    memo: 'Lorem Ipsum',
                    class: 'Lorem Ipsum',
                    amount: '100000',
                    status: 'Active'
                },
                {   
                    id: 3,
                    date: '05/10/2020',
                    pay_order: 'Antonio Kendrick',
                    check_no: '09978787',
                    memo: 'Lorem Ipsum',
                    class: 'Lorem Ipsum',
                    amount: '100000',
                    status: 'Inactive'
                },
                { 
                    id: 4,
                    date: '05/10/2020',
                    pay_order: 'Antonio Kendrick',
                    check_no: '09978787',
                    memo: 'Lorem Ipsum',
                    class: 'Lorem Ipsum',
                    amount: '100000',
                    status: 'Paid'
                },
            ]
        }
    },
    methods: {
        checkStatus (status) {
            if (status == 'Draft' || status == 'Inactive') {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style>

</style>