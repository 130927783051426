<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>Input Internal Stock Data</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveProduct()" :loading="buttonLoading" :disabled="buttonLoading">
                Submit
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <v-container fluid class="crystal-admin-form">
        <v-row class="justify-space-around">
                <v-col cols="5">
                    <label>Vendor: </label>
                    <v-text-field
                        solo
                        placeholder="Enter Vendor name"
                        dense
                    ></v-text-field>
                    <label>Address: </label>
                    <v-textarea
                        solo
                        placeholder="Address"
                        dense
                    ></v-textarea>
                    <label>Memo</label>
                    <v-textarea
                        solo
                        placeholder="Memo"
                        dense
                    >
                    </v-textarea>
                </v-col>
                <v-col cols="5">
                    <label>Terms: </label>
                    <v-text-field
                        solo
                        placeholder="Enter terms"
                        dense
                    ></v-text-field>
                    <label>Date</label>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        > 
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="from_date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="from_date"
                                label="Date"
                                readonly
                                v-bind="attrs"
                                solo
                                v-on="on"
                                append-icon="mdi-calendar"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="from_date"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(from_date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                    </v-row>
                    <label>Amount Due</label>
                    <v-text-field
                        placeholder="Enter amount"
                        prefix="Rp |"
                        solo
                        dense
                    >
                    </v-text-field>
                    <label>Bills Due</label>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        > 
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="from_date"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="bills_date"
                                label="Bills Date"
                                readonly
                                solo
                                dense
                                v-bind="attrs"
                                v-on="on"
                                append-icon="mdi-calendar"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="bills_date"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu2.save(bills_date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                    </v-row>
                    <label>Class</label>
                    <v-select
                        solo
                        dense
                        label="Class"
                    >
                    </v-select>
                </v-col>
        </v-row>
        <hr>
        <v-container>
            <ReturnOrder
                v-for="(product, index) in products"
                :key="product.id"
                :product="product"
                :index="index"
                @deleteProduct="deleteProduct"
            />
        </v-container>
        <v-row class="justify-center">
            <v-col cols="2">
                <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="addProduct()">
                    + Add new Item
                </v-btn>
            </v-col>
        </v-row>
      </v-container>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveProduct()" :loading="buttonLoading" :disabled="buttonLoading">
                Submit
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import ReturnOrder from '@/components/ReturnOrder.vue'

export default {
    name: 'AddInternalStock',
    components: {
        ReturnOrder
    },
    data () {
        return {
            products: [
                {
                    product_name: '',
                    quantity: 0
                }
            ],
            from_date: new Date().toISOString().substr(0, 10),
            bills_date: new Date().toISOString().substr(0,10),
            menu: false,
            menu2: false,
            buttonLoading: false
        }
    },
    methods: {
        saveProduct () {
            this.buttonLoading = !this.buttonLoading;
        },
        deleteProduct(index) {
            this.products.splice(index, 1)
        },
        addProduct () {
            let newProduct = {
                product_name: '',
                price: 0,
                quantity: 0
            }
            this.products.push(newProduct)
        },
    }
}
</script>

<style>
</style>