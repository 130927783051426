<template>
   <CustomerForm 
    :customer="customer"
    @saveCustomer="saveCustomer"
    :buttonLoading="buttonLoading"
   />
</template>

<script>
import server from '../api';
import CustomerForm from '@/components/CustomerForm.vue'

export default {
    name: 'AddCustomer',
    components: {
        CustomerForm
    },
    data () {
        return {
            customer: {
                company_name: '',
                firstName: '',
                middleName: '',
                lastName: '',
                taxStatus: '',
                type: '',
                CustomerContact: {
                    addressLine1: '',
                    contactNo: '',
                    email: '',
                    invoice_address: '',
                    invoice_city: '',
                    city_id: '',
                    province_id: '',
                    postal_code: '',
                    gender: 0
                }
            },
            buttonLoading: false
        }
    },
    
    methods: {
        saveCustomer () {
            this.buttonLoading = !this.buttonLoading;
            server.post('/customers', {
                gender: this.customer.CustomerContact.gender,
                company_name: this.customer.company_name,
                firstName: this.customer.firstName,
                middleName: this.customer.middleName,
                lastName: this.customer.lastName,
                contactNo: this.customer.CustomerContact.contactNo,
                email: this.customer.CustomerContact.email,
                taxStatus: this.customer.taxStatus,
                type: this.customer.type,
                addressLine1: this.customer.CustomerContact.addressLine1,
                city_id: this.customer.CustomerContact.city_id,
                postal_code: this.customer.CustomerContact.postal_code,
                province_id: this.customer.CustomerContact.province_id,
                invoice_address: this.customer.CustomerContact.invoice_address,
                invoice_city: this.customer.CustomerContact.invoice_city,
                invoice_province: this.customer.CustomerContact.invoice_province
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Menambahkan Customer dengan Name ${result.data.Customer.firstName}`)
                    this.$router.push({ name: 'Customer' });
                })
                .catch(error => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', error.response.statusText)
                });
        }
    }
}
</script>

<style>

</style>