<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center" justify="space-around">
        <v-col
        cols="6"
        >
            <h2>Internal Stock Transfer</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="6"
                >   
                    <v-text-field
                        label="Search Vendor ID"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                        v-model="searchQuery"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/stock">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add New Stock
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-start">
        <v-col cols="3">
            <div class="px-3 crystal-admin-form">
                <label>Date</label>
                <v-row>
                    <v-col
                    cols="12"
                    >
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="filterDate"
                            transition="scale-transition"
                            offset-y
                            min-width="350px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filterDateText"
                                label="Choose Date"
                                readonly
                                v-bind="attrs"
                                append-icon="mdi-calendar"
                                clearable
                                hide-details
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="filterDate"
                                no-title
                                scrollable
                                range
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(filterDate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </div>  
        </v-col>
        <v-col cols="3">
            <div class="px-3 crystal-admin-form">
                <label>From - Destination</label>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="From"
                            clearable
                            v-model="filterFrom"
                        />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="To"
                            clearable
                            v-model="filterTo"
                        />
                    </v-col>
                </v-row>
            </div>   
        </v-col>
        <v-col cols="3">
            <div class="px-3 crystal-admin-form" fill-height>
                <label>Type</label>
                <v-select
                    placeholder="Type"
                    :items="types"
                    v-model="filterType"
                ></v-select>
            </div>       
        </v-col>
        <!-- <v-col cols="3">
            <div class="px-3 crystal-admin-form" height="100%">
                <label>Stock</label>    
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="min"
                        />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            placeholder="max"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-col> -->
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <Alert />
            <v-data-table
                :headers="headers"
                :items="internalTransfers"
                :options="pagination"
                @update:options="paginate"
                :footer-props="{
                    'items-per-page-options': listSize
                }"
                
                class="elevation-1"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        v-model="item.status"
                    ></v-switch>
                </template>
                <template v-slot:[`item.from_date`]="{ item }">
                    <td>{{ formatDate(item.from_date) }}</td>
                </template>
                <template v-slot:[`item.from`]="{ item }">
                    <td v-if="item.Vendor">{{ item.Vendor.company_name }}</td>
                    <td v-else-if="item.ShipFromLocation">{{ item.ShipFromLocation ? item.ShipFromLocation.LocationName : '' }}</td>
                </template>
                <template v-slot:[`item.qty`]="{ item }">
                    <span v-for="(product, index) in item.InternalTransferLines.slice(0, 3)" :key="product.id">
                        {{ product.Item ? product.Item.name : '' }}{{` (x${product.qty})` }}{{ index === 2 ? `...${item.InternalTransferLines.length - 3} more` : index === item.InternalTransferLines.length-1 ? '' : ',' }}
                    </span>
                </template>
                <template v-slot:[`item.destination`]="{ item }">
                    <td v-if="item.ToVendor">{{ item.ToVendor.company_name }}</td>
                    <td v-else-if="item.ShipToLocation">{{ item.ShipToLocation ? item.ShipToLocation.LocationName : '' }}</td>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ formatDate(item.createdAt) }}
                </template>
                <template v-slot:[`item.type`]="{ item }">
                    <td v-if="item.type == 'inter_warehouse'">Inter Warehouse</td>
                    <td v-else-if="item.type == 'adjustment'">Adjustment</td>
                    <td v-else-if="item.type == 'purchases'">Purchases</td>
                    <td v-else-if="item.type == 'purchase_return'">Purchase Return</td>
                </template>
                <template v-slot:[`item.memo`]="{ item }">
                    <td>{{ item.InternalTransferLines[0] ? item.InternalTransferLines[0].memo : 'Lorem Ipsum' }}</td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small text class="mr-2" @click="getDetailInternalStock(item)">mdi-eye</v-icon>
                    <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DeleteDialog 
        :message="`Remove Internal Stock ${internal_transfer.id} ?`"
        :item="internal_transfer"
        :showDelete="showDelete"
        @deleteItem="deleteStock"
        @closeDialog="closeDialog"
    />
    <DetailInternalStock
        :internal_stock="internal_transfer"
        :detail_internal_stock="detail_internal_stock"
        @closeDetail="closeDetail"
    />
  </v-container>
</template>

<script>
import Alert from '@/components/Alert.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import server from '../api';
import DetailInternalStock from '@/components/DetailInternalStock.vue';

export default {
    name: 'Stock',
    components: {
        Alert,
        DeleteDialog,
        DetailInternalStock
    },
    data () {
        return {
            headers: [
                { text: 'Date', value: 'from_date'},
                { text: 'From', value: 'from'},
                { text: 'Destination', value: 'destination'},
                { text: 'Type', value: 'type'},
                { text: 'Qty', value: 'qty'},
                { text: 'Memo', value: 'memo'},
                { text: 'Action', value: 'actions' }
            ],
            listSize: [15, 25, 50, 100, -1],
            pagination: {
                descending: !!this.$route.query.desc,
                itemsPerPage: +this.$session.get("stockItemPerPage") || 15,
                page: +this.$session.get("stockPage") || 1,
                //totalItems: this.totalOrders,
            },
            internal_transfer: {},
            showDelete: false,
            types: [
                {
                    text: 'All',
                    value: ''
                },
                {
                    text: 'Purchases',
                    value: 'purchases'
                },
                {
                    text: 'Inter-Warehouse',
                    value: 'inter_warehouse'
                },
                {
                    text: 'Adjustment',
                    value: 'adjustment'
                },
                {
                    text: 'Purchase Return',
                    value: 'purchase_return'
                }
            ],
            filterType: '',
            detail_internal_stock: false,
            filterDate: [],
            searchQuery: "",
            filterTo: "",
            filterFrom: "",
        }
    },
    watch: {
        // filterDate (val) {
        //     const query = this.$route.query;
        //     const obj = Object.assign({}, query);
        //     obj.start_date = val[0] ? val[0] : '';
        //     obj.end_date = val[1] ? val[1] : '';
        //     if (!obj.start_date) delete obj.start_date;
        //     if (!obj.end_date) delete obj.end_date;
        //     this.$router.replace({
        //         ...this.$router.currentRoute,
        //         query: obj
        //     });
        // },
    },
    methods: {
        showDeleteDialog(item) {
            this.internal_transfer = item;
            this.showDelete = !this.showDelete;
        },
        closeDialog () {
            this.showDelete = !this.showDelete;
        },
        closeDetail () {
            this.detail_internal_stock = !this.detail_internal_stock;
        },
        deleteStock (id) {
            this.showDelete = !this.showDelete;
            server.delete(`/internal_transfer/${id}`, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.$store.dispatch('getInternalTransfer');
                    this.$store.commit('SET_SUCCESS', `Berhasil menghapus Internal Transfer ${result.data.InternalTransfer.id}`)
                })
                .catch(err => {
                    this.$store.commit('SET_ERROR', err.response.data.errors[0].message)
                });
        },
        getDetailInternalStock(item) {
            this.internal_transfer = item;
            this.detail_internal_stock = !this.detail_internal_stock;
        },
        paginate (val) {
            // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            this.$session.set("stockPage", val.page);
            this.$session.set("stockItemPerPage", val.itemsPerPage);
            //console.log(this.$session.get("page"));
            if (val.itemsPerPage !== this.listSize[0]) obj.per_page = val.itemsPerPage;
            if (val.descending) obj.desc = 'true';
            else delete obj.desc;
            obj.orderby = val.sortBy;
            obj.page = val.page;
            obj.per_page = val.itemsPerPage;
            // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
            let same = true;
            for (let key in query) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            // to handle the case when a KEY exists in OBJ but not in query
            for (let key in obj) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            if (same) this.fetchData(); // page has been manually reloaded in the browser
            else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: obj
                });
            }
        },
    },
    computed: {
        filterDateText: {
            get () {
                return this.filterDate.join(' - ');
            },
            set () {
                this.filterDate = [];
            }
        },
        internalTransfers () {
            var result = this.$store.state.internalTransfers;
            
            if (this.filterDate[1]) {
                var endDate = this.filterDate[1]+"T00:00:00.000Z"
                var beginDate = this.filterDate[0]+"T00:00:00.000Z"
                result = result.filter(it => it.from_date >= beginDate && it.from_date <= endDate)
            }
            if (this.filterType) {
                result = result.filter(it => it.type == this.filterType)           
            }
            if (this.searchQuery !== "" && this.searchQuery !== null) {
                result = result.filter(it => it.VendorId == this.searchQuery);            
            }           
            if (this.filterTo !== "" && this.filterTo !== null) {
                result = result.filter(it => it.ShipToLocationId == this.filterTo);
            }            
            if (this.filterFrom !== "" && this.filterFrom !== null) {
                result = result.filter(it => it.ShipFromLocationId == this.filterFrom);
            }
            return result;
        }
    },
    created () {
        this.$store.dispatch('getInternalTransfer')
    }
}
</script>

<style>

</style>