<template>
  <v-container fluid class="admin-page">
    <v-row>
      <v-col cols="6">
        <h2>Faq</h2>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="4" offset="7">
            <router-link to="/dashboard/add/faq">
              <v-btn class="text--futura text--white text-capitalize" dark block>
                Add Faq
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <Alert />
        <v-data-table :loading="tableLoading" :headers="headers" :items="faqs" :items-per-page="5" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small text @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DeleteDialog :message="`Remove Faq ${faq.title} ?`" :item="faq" :showDelete="showDelete" @deleteItem="deleteFaq"
      @closeDialog="closeDialog" />
  </v-container>
</template>

<script>
import Alert from "../components/Alert.vue";
import DeleteDialog from "../components/DeleteDialog.vue";
import server from "../api";

export default {
  name: "Faq",
  components: {
    Alert,
    DeleteDialog,
  },
  data() {
    return {
      headers: [
        { text: "Title", value: "title" },
        { text: "Body", value: "body" },
        { text: "Locale", value: "locale" },
        { text: "Category", value: "faq_category.name" },
        { text: "Action", value: "actions" },
      ],
      showDelete: false,
      faq: {}
    };
  },
  methods: {
    showDeleteDialog(faq) {
      this.faq = faq;
      this.showDelete = true;
    },
    closeDialog() {
      this.showDelete = false;
    },
    deleteFaq(id) {
      server
        .delete(`/faqs/${id}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((result) => {
          this.showDelete = false;
          this.$store.dispatch("getFaq");
          this.$store.commit(
            "SET_SUCCESS",
            `Berhasil Menghapus Faq ${result.data.title}`
          );
        })
        .catch((err) => {
          this.$store.commit("SET_ERROR", err.data.response.errors[0].message);
        });
    },
    editItem(item) {
      this.faq = item;
      this.$router.push({
        path: `/dashboard/edit/faq/${this.faq.id}`,
      });
    },
  },
  computed: {
    faqs() {
      return this.$store.state.faq;
    },
    tableLoading() {
      return this.$store.state.tableLoading;
    },
  },
  created() {
    this.$store.dispatch("getFaq");
  },
};
</script>