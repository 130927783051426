<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>Add New Return</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveReturn()" :loading="buttonLoading" :disabled="buttonLoading">
                Save
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <Alert />
      <v-container fluid class="crystal-admin-form">
        <v-row class="justify-space-around">
                <v-col cols="5">
                    <label>Order Number: </label>
                    <v-autocomplete
                        solo
                        placeholder="Enter Order ID"
                        :items="select_orders"
                        item-text="id"
                        v-model="selectedOrder"
                    ></v-autocomplete>
                    <label>Ship By</label>
                    <v-text-field
                        solo
                        placeholder="Ship By"
                        v-model="ship_by"
                    ></v-text-field>
                    <label>Ship To: </label>
                    <v-autocomplete
                        solo
                        placeholder="Choose Location"
                        :items="warehouses"
                        item-text="LocationName"
                        item-value="id"
                        v-model="ShipFromLocationId"
                    ></v-autocomplete>
                    <label>Money Returned: </label>
                    <v-text-field
                        solo
                        prefix="Rp |"
                        placeholder="Enter Price"
                        v-model="money_returned"
                    ></v-text-field>
                </v-col>
                <v-col cols="5">
                    <label>Actions: </label>
                    <v-select
                        solo
                        placeholder="Choose Action"
                        :items="actions"
                        v-model="action"
                    ></v-select>
                    <label>Memo</label>
                    <v-textarea
                        solo
                        placeholder="Memo"
                        v-model="memo"
                    >
                    </v-textarea>
                </v-col>
        </v-row>
        <hr>
        <v-container>
            <ReturnOrder
                v-for="(product, index) in products"
                :key="product.id"
                :product="product"
                :index="index"
                @deleteProduct="deleteProduct"
            />
        </v-container>
      </v-container>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveReturn()" :loading="buttonLoading" :disabled="buttonLoading">
                Save
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import ReturnOrder from '@/components/ReturnOrder.vue'
import Alert from '@/components/Alert.vue'
import server from '../api'

export default {
    name: 'AddDataEntry',
    components: {
        ReturnOrder,
        Alert
    },
    data () {
        return {
            ship_by: '',
            ShipFromLocationId: '',
            memo: '',
            action: '',
            money_returned: 0,
            actions: [
                { 
                    text: 'Return',
                    value: 'return'
                },
                {
                    text: 'Refund',
                    value: 'refund'
                }
            ],
            buttonLoading: false
        }
    },
    methods: {
        saveReturn () {
            this.buttonLoading = !this.buttonLoading;
            if (this.products.length) {
                server.post('/returns', {
                    OrderId: this.selectedOrder.id,
                    ShipFromLocationId: this.ShipFromLocationId,
                    ship_by: this.ship_by,
                    memo: this.memo,
                    items: JSON.stringify(this.products),
                    money_returned: this.money_returned
                }, {
                    headers: {
                        token: localStorage.token
                    }
                })
                    .then(result => {
                        this.buttonLoading = !this.buttonLoading;
                        this.$store.commit('SET_SUCCESS', `Berhasil menambahkan Return ${result.data.Return.id}`);
                        this.$router.push({ name: 'Return' });
                    })
                    .catch(error => {
                        this.buttonLoading = !this.buttonLoading;
                        this.$store.commit('SET_ERROR', `${error.response.data.errors[0].message}`);
                    });
            } else {
                this.buttonLoading = !this.buttonLoading;
                this.$store.commit('SET_ERROR', `Tidak ada Produk yang direturn`);
            }
        },
        addProduct () {
            let newProduct = {
                ItemId: '',
                price: 0,
                qty: 0
            }
            this.products.push(newProduct)
        },
        deleteProduct(index) {
            this.products.splice(index, 1)
        }
    },
    computed: {
        warehouses () {
            return this.$store.state.warehouses;
        },
        select_orders () {
            return this.$store.state.select_orders;
        },
        products () {
            return this.selectedOrder.OrderLine ? this.selectedOrder.OrderLine.items : '';
        },
        selectedOrder: {
            get: function() {
                return this.$store.state.order
            },
            set: function(value) {
                this.$store.dispatch('getOrderById', { id: value });
            }
        }
    },
    created () {
        this.$store.dispatch('getWarehouses');
        this.$store.dispatch('getOrdersLite');
        if (this.$route.params.id) {
            this.$store.dispatch('getOrderById', { id: this.$route.params.id });
        }
    },
}
</script>

<style>
</style>