<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>Add New Account</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveProduct()">
                {{ tab == 1 ? 'Next' : 'Submit' }}
            </v-btn>
        </v-col>
      </v-row>
      <v-row class="crystal-admin-form justify-space-around px-10">
          <v-col cols="12">
              <span><a href="" class="breadcrumb-nav" @click.prevent="toggleTab(1)">Choose account type</a></span>
              <span> > <a href="" class="breadcrumb-nav" @click.prevent="toggleTab(2)">Account Description</a></span>
              <v-row v-if="tab == 1">
                  <v-col cols="12">
                    <span>Categorize money your business earns or spends</span>
                  </v-col>
                  <v-col cols="2" class="pt-0 pb-0">
                      <v-checkbox
                        label="Income"
                      ></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="pt-0 pb-0">
                      <v-checkbox
                        label="Expense"
                      ></v-checkbox>
                  </v-col>
              </v-row>
              <v-row v-if="tab == 1">
                  <v-col cols="12">
                    <span>or, track the value of your assets and liabilities</span>
                  </v-col>
                  <v-col cols="2" class="pt-0 pb-0">
                      <v-checkbox
                        label="Fixed Assets"
                      ></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="pt-0 pb-0">
                      <v-checkbox
                        label="Bank"
                      ></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="pt-0 pb-0">
                      <v-checkbox
                        label="Loan"
                      ></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="pt-0 pb-0">
                      <v-checkbox
                        label="Credit Card"
                      ></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="pt-0 pb-0">
                      <v-checkbox
                        label="Equity"
                      ></v-checkbox>
                  </v-col>
              </v-row>
              <v-row v-if="tab == 1">
                  <v-col cols="12">
                    <span>Other account types</span>
                  </v-col>
                  <v-col cols="4">
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        hide-details
                      >
                      </v-select>
                  </v-col>
                  <v-col cols="12">
                    <div class="new-account-description align-center justify-center">
                      <span>Click and account type on the left to see a description here.</span>
                    </div>
                  </v-col>
              </v-row>
              <v-row v-if="tab == 2" class="justify-space-around">
                <v-col cols="5">
                  <label>Account Name: </label>
                  <v-text-field
                      solo
                      dense
                      placeholder="Enter Vendor name"
                  ></v-text-field>
                  <label>Descriptional (Optional): </label>
                  <v-textarea
                      solo
                      dense
                      placeholder="Enter Description"
                  ></v-textarea>
                  <label>Note: </label>
                  <v-text-field
                      solo
                      dense
                      placeholder="Enter Note"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-checkbox
                    label="Sub-Account of"
                  >
                  </v-checkbox>
                  <v-select
                      solo
                      dense
                      label="Enter terms"
                  ></v-select>
                  <label>Currency</label>
                  <v-select
                      solo
                      dense
                      label="Enter currency"
                  ></v-select>
                  <label>Note: </label>
                  <v-text-field
                      solo
                      dense
                      placeholder="Enter Note"
                  ></v-text-field>
                </v-col>
              </v-row>
          </v-col>
      </v-row>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white text-capitalize" dark block @click.prevent="saveProduct()">
                {{ tab == 1 ? 'Next' : 'Submit' }}
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'AddNewAccount',
    data () {
        return {
          tab: 1,
        }
    },
    methods: {
      toggleTab(tab) {
        this.tab = tab;
      }
    }
}
</script>

<style scoped>
  .new-account-description {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 100px;
    display: flex;
  }
  .breadcrumb-nav {
    text-decoration: none;
    color: black;
  }
  .breadcrumb-nav:active {
    text-decoration: underline;
  }
</style>