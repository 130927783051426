<template>
  <v-dialog
    v-model="detail_return"
    @click:outside="closeDetail"
  >
    <v-card>
        <v-card-title>
            Return Details {{ order.id }}
            <v-spacer></v-spacer>   
            <v-chip color="primary">
                {{ order.status }}
            </v-chip>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row class="justify-space-around">
                    <v-col cols="3">
                        <h4 class="text--futura">Date</h4><br>
                        <span class="text--futura">
                            {{ formatDate(order.createdAt) }}
                        </span>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="text--futura">Customer</h4><br>
                        <span class="text--futura">
                           {{ order.Order.Customer.fullName }}
                        </span>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="text--futura">Contact</h4><br>
                        <span class="text--futura">
                            {{ order.Order.Customer.CustomerContact.contactNo }}
                        </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <h4 class="text--futura">Product</h4>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="text--futura">Quantity</h4>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="text--futura">Price Each</h4>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="text--futura">Price</h4>
                    </v-col>
                </v-row>
                <hr>
                <v-row v-for="product in order.items" :key="product.id">
                    <v-col cols="3">
                        <span class="text--futura">{{ product.Item.name }}</span>
                    </v-col>
                    <v-col cols="3">
                        <span class="text--futura">{{ product.qty }}</span>
                    </v-col>
                    <v-col cols="3">
                        <span class="text--futura">{{ formatHarga(product.price) }}</span>
                    </v-col>  
                    <v-col cols="3">
                        <span class="text--futura">{{ formatHarga(sub_total(product.price, product.qty)) }}</span>
                    </v-col>
                </v-row>
                <hr>
            </v-container>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
    name: 'DetailReturn',
    props: {
        detail_return: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object,
        }
    },
    methods: {
        sub_total(price, qty) {
            return price * qty;
        },
        closeDetail () {
            this.$emit('closeDetail');
        }
    }
}
</script>

<style>

</style>