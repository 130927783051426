<template>
  <FaqCategory :faqCategory="faqCategory" @saveFaqCategory="saveFaqCategory" :buttonLoading="buttonLoading" />
</template>

<script>
import server from "../api";
import FaqCategory from "@/components/FaqCategoryForm.vue";

export default {
  name: "EditFaqCategory",
  data() {
    return {
      buttonLoading: false,
    };
  },
  components: {
    FaqCategory,
  },
  methods: {
    saveFaqCategory() {
      this.buttonLoading = !this.buttonLoading
      server.put(`/faq_categories/${this.faqCategory.id}`, this.faqCategory, {
        headers: {
          token: localStorage.token,
        }
      })
        .then(response => {
          this.$store.commit("SET_SUCCESS", `Success edit data faq category ${response.data.name}`)
          this.$router.push({ name: "FaqCategory" })
        })
        .catch(err => {
          this.$store.commit("SET_ERROR", err.response.data.errors.message);
        })
        .finally(() => this.buttonLoading = !this.buttonLoading)
    },
  },
  computed: {
    faqCategory() {
      return this.$store.state.detailFaqCategory
    }
  },
  created() {
    this.$store.dispatch('getFaqCategoryById', { id: this.$route.params.id });
  }
};
</script>

<style></style>
