<template>
  <v-container fluid class="admin-page">
    <v-row>
      <v-col cols="6">
        <h2>Recipe</h2>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="4" offset="7">
            <router-link to="/dashboard/add/recipe">
              <v-btn class="text--futura text--white text-capitalize" dark block>
                Add Recipe
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <Alert />
        <v-data-table :loading="tableLoading" :headers="headers" :items="recipes" :items-per-page="5" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DeleteDialog :message="`Remove ${recipe.title} ?`" :item="recipe" :showDelete="showDelete" @deleteItem="deleteRecipe"
      @closeDialog="closeDialog" />
  </v-container>
</template>

<script>
import Alert from "../components/Alert.vue";
import DeleteDialog from "../components/DeleteDialog.vue";
import server from "../api";

export default {
  name: "Recipe",
  components: {
    Alert,
    DeleteDialog,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "recipe_name" },
        { text: "Category", value: "RecipeCategory.name" },
        { text: "Cooking Method", value: "cooking_method" },
        { text: "Author", value: "author" },
        { text: "Action", value: "actions" },
      ],
      showDelete: false,
      recipe: {}
    };
  },
  methods: {
    showDeleteDialog(recipe) {
      this.recipe = recipe;
      this.showDelete = true;
    },
    closeDialog() {
      this.showDelete = false;
    },
    deleteRecipe(id) {
      server
        .delete(`/nutrition/recipe/${id}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then(() => {
          this.showDelete = false;
          this.$store.dispatch("getRecipe");
          this.$store.commit(
            "SET_SUCCESS",
            `Berhasil Menghapus Recipe`
          );
        })
        .catch((err) => {
          this.$store.commit("SET_ERROR", err.data.response.errors[0].message);
        });
    },
    editItem(item) {
      this.recipe = item;
      this.$router.push({
        path: `/dashboard/edit/recipe/${this.recipe.slug}`,
      });
    },
  },
  computed: {
    recipes() {
      return this.$store.state.recipe;
    },
    tableLoading() {
      return this.$store.state.tableLoading;
    },
  },
  created() {
    this.$store.dispatch("getRecipe");
  },
};
</script>