<template>
  <v-container fluid>
    <v-row class="justify-space-around">
      <v-col cols="5">
        <label>Order ID</label>
        <v-text-field
          solo
          placeholder="Enter Order ID"
          v-model="invoice.order_id"
          disabled
        ></v-text-field>
        <label>Invoice Date: </label>
        <div>
          <date-picker
            class="date-picker"
            v-model="invoice.invoice_date"
            style="width:100%"
            type="date"
            valueType="format"
            placeholder="Date for Invoice"
            :formatter="momentFormat"
          ></date-picker>
        </div>
      </v-col>
      <v-col cols="5">
        <label>Type</label>
        <v-select
          solo
          placeholder="Choice Type"
          :items="types"
          item-text="text"
          item-value="value"
          v-model="invoice.type"
        ></v-select>
        <label>Memo</label>
        <v-text-field
          solo
          placeholder="Enter Memo"
          v-model="invoice.memo"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-5">
      <v-col cols="12">
        <h3 style="text-align: center;">
          {{ form_type == "edit" ? "Edit" : "Add" }} Data Product To Invoice
        </h3>
        <v-progress-linear
          indeterminate
          v-if="loadingGet"
          color="cyan"
        ></v-progress-linear>
      </v-col>
      <v-col cols="12">
        <v-row
          style="alignItems:center"
          v-for="(item, index) in invoice.items"
          :key="index"
        >
          <v-col cols="4" class="py-0">
            <label>Product Name</label>
            <v-autocomplete
              solo
              v-model="item.id"
              :items="products"
              item-text="name"
              item-value="id"
              placeholder="Choice Product"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3" class="py-0">
            <label>Qty</label>
            <v-text-field
              solo
              placeholder="Enter Quantity"
              v-model="item.qty"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="py-0">
            <label>Price</label>
            <v-text-field
              solo
              placeholder="Enter Price"
              prefix="Rp. "
              v-model="item.price"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="py-0">
            <v-icon
              aria-hidden="false"
              x-large
              @click="deleteProductForm(index)"
            >
              mdi-minus-box
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col col="12" class="pa-0">
        <v-row class="justify-start pl-3">
          <v-col cols="3" class="py-0">
            <v-btn color="primary" dark @click="addProductForm">
              Add Fields
              <v-icon dark right>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-alert color="red" type="error" v-if="errorMessage">
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="justify-end">
      <v-col cols="2">
        <v-btn
          type="submit"
          class="text--futura text--white text-capitalize"
          dark
          block
          @click.prevent="saveInvoice()"
          :loading="loading"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
export default {
  name: "InvoiceForm",
  components: {
    DatePicker,
  },
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {
          order_id: "",
          type: "",
          memo: "",
          invoice_date: "",
          items: [],
        };
      },
    },
    form_type: {
      type: String,
      default: "add",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingGet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format("YYYY-MM-DD") : "";
        },
        parse: (value) => {
          return value ? new Date(value) : null;
        },
      },
      types: [
        {
          text: "Payment",
          value: "payment",
        },
        {
          text: "Adjustment",
          value: "adjustment",
        },
      ],
    };
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
    errorMessage() {
      return this.$store.state.errorMessage.message;
    },
  },
  methods: {
    addProductForm() {
      this.$emit("addProductForm");
    },
    deleteProductForm(index) {
      this.$emit("deleteProductForm", index);
    },
    saveInvoice() {
      this.$emit("saveInvoice");
    },
  },
  created() {
    this.$store.dispatch("getProducts");
  },
};
</script>
