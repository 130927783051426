<template>
  <v-container fluid class="admin-page">
      <v-row class="justify-space-between">
        <v-col
        cols="7"
        >
            <h2>{{ formType.text }} Customer</h2>
        </v-col>
        <v-col cols="2">
            <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveCustomer()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ formType.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
            </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
          <Alert />
          <Overlay
            :overlay="overlay"
          />
      </v-row>
      <v-row class="crystal-admin-form justify-space-around pt-5">
            <v-col cols="5">
                <label>Company Name: </label>
                <v-text-field
                    solo
                    placeholder="Company Name"
                    v-model="customer.company_name"
                ></v-text-field>
                <label>First Name: </label>
                <v-text-field
                    solo
                    placeholder="First Name"
                    v-model="customer.firstName"
                ></v-text-field>
                <label>Middle Name: </label>
                <v-text-field
                    solo
                    placeholder="Middle Name"
                    v-model="customer.middleName"
                ></v-text-field>
                <label>Last Name: </label>
                <v-text-field
                    solo
                    placeholder="Last Name"
                    v-model="customer.lastName"
                ></v-text-field>
                <label>Phone Number: </label>
                <v-text-field
                    solo
                    placeholder="Phone Number"
                    prefix="+62 | "
                    v-model="customerContact"
                ></v-text-field>
                <label>Type: </label>
                <v-select
                    solo
                    :items="customer_types"
                    label="Choose Type"
                    v-model="customer.type"
                ></v-select>
                <label>Tax Status: </label>
                <v-select
                    solo
                    :items="tax_statuses"
                    label="Choose Status"
                    v-model="customer.taxStatus"
                ></v-select>
            </v-col>
            <v-col cols="5">
                <label>Email: </label>
                <v-text-field
                    solo
                    placeholder="Enter Customer email"
                    v-model="customer.CustomerContact.email"
                ></v-text-field>
                <label>Gender: </label>
                <v-row class="justify-start">
                    <v-radio-group
                        v-model="customer.CustomerContact.gender"
                        row
                    >
                        <v-radio label="Male" :value="0"></v-radio>
                        <v-radio label="Female" :value="1"></v-radio>
                    </v-radio-group>
                </v-row>
                <label>Invoice Address: </label>
                <v-textarea
                    solo
                    placeholder="Address"
                    v-model="customer.CustomerContact.invoice_address"
                ></v-textarea>
                <label>Invoice Province: </label>
                <v-select
                    solo
                    placeholder="Invoice Province"
                    v-model="customer.CustomerContact.invoice_province"
                    :items="provinces"
                    item-text="name"
                    item-value="province_id"
                ></v-select>
                <div
                    v-if="customer.CustomerContact.invoice_province"
                >
                    <label>Invoice City: </label>
                    <v-select
                        solo
                        placeholder="Invoice City"
                        v-model="customer.CustomerContact.invoice_city"
                        :items="invoice_cities"
                        item-text="name"
                        item-value="city_id"
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{ data.item.type }} {{ data.item.name }}
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ data.item.type }} {{ data.item.name }}
                        </template>
                    </v-select>
                </div>
                <label>Ship to Address: </label>
                <v-textarea
                    solo
                    placeholder="Ship To Address"
                    v-model="customer.CustomerContact.addressLine1"
                ></v-textarea>
                <label>Ship to Province: </label>
                <v-select
                    solo
                    placeholder="Ship to Province"
                    v-model="customer.CustomerContact.province_id"
                    :items="provinces"
                    item-text="name"
                    item-value="province_id"
                ></v-select>
                <div
                    v-if="customer.CustomerContact.province_id"
                >
                    <label>Ship to City: </label>
                    <v-select
                        solo
                        placeholder="Ship to City"
                        v-model="customer.CustomerContact.city_id"
                        :items="shipment_cities"
                        item-text="name"
                        item-value="city_id"
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{ data.item.type }} {{ data.item.name }}
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ data.item.type }} {{ data.item.name }}
                        </template>
                    </v-select>
                </div>
                <label>Postal Code:</label>
                    <v-text-field
                        solo
                        placeholder="Enter Postal Code"
                        v-model="customer.CustomerContact.postal_code"
                    ></v-text-field>
            </v-col>
      </v-row>
      <v-row class="justify-end">
          <v-col cols="2">
              <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveCustomer()" :loading="buttonLoading" :disabled="buttonLoading">
                {{ formType.value == 'new' ? 'Save' : 'Update' }}
                <template v-slot:loader>
                    <v-progress-circular
                        indeterminate
                        color="black"
                    ></v-progress-circular>
                </template>
              </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Alert from '@/components/Alert.vue'
import Overlay from '@/components/Overlay.vue'

export default {
    name: 'CustomerForm',
    components: {
        Alert,
        Overlay
    },
    props: {
        customer: {
            type: Object,
            default: () => {
                return {
                    company_name: '',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    taxStatus: '',
                    type: '',
                    CustomerContact: {
                        addressLine1: '',
                        contactNo: '',
                        email: '',
                        invoice_address: '',
                        invoice_city: '',
                        invoice_province: '',
                        city_id: '',
                        province_id: '',
                        gender: 0
                    }
                }
            }
        },
        formType: {
            type: Object,
            default: () => {
                return {
                    text: 'Add New',
                    value: 'new'
                }
            }
        },
        buttonLoading: {
            type: Boolean,
            default: false
        },
        overlay: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            tax_statuses: [
                'PKP',
                'Non PKP'
            ],
        }
    },
    computed: {
        customerContact: {
            get () {
                return this.removeCountryCode(this.customer.CustomerContact.contactNo);
            },
            set (value) {
                this.customer.CustomerContact.contactNo = value;
            }
        },
        cities () {
            return this.$store.state.cities;
        },
        provinces () {
            return this.$store.state.provinces;
        },
        invoice_cities () {
            let province_id = this.customer.CustomerContact.invoice_province;
            return this.$store.state.cities.filter(city => city.province_id == province_id);
        },
        shipment_cities () {
            let province_id = this.customer.CustomerContact.province_id;
            return this.$store.state.cities.filter(city => city.province_id == province_id)
        },
        customer_types () {
            return this.$store.state.customer_types;
        }
    },
    methods: {
        saveCustomer() {
            this.$emit('saveCustomer');
        }
    },
    created () {
        this.$store.dispatch('getProvinces');
        this.$store.dispatch('getCities');
    }
}
</script>

<style>

</style>