<template>
  <Recipe :recipe="recipe" @saveRecipe="saveRecipe" :buttonLoading="buttonLoading" />
</template>

<script>
import server from "../api";
import Recipe from "@/components/RecipeForm.vue";

export default {
  name: "AddRecipe",
  data() {
    return {
      recipe: {
        recipe_name: "",
        category: "",
        description: "",
        servingSize: "",
        cookingMethod: "",
        recipeInfo: [],
        instructions: "",
        notes: ""
      },
      buttonLoading: false,
    };
  },
  components: {
    Recipe,
  },
  methods: {
    saveRecipe() {
      this.buttonLoading = !this.buttonLoading
      let data = new FormData();
      Object.keys(this.recipe).forEach(key => data.append(key, this.recipe[key]));
      let recipeInfo = this.recipe.recipeInfo
      data.delete("recipeInfo")
      recipeInfo.forEach(element => {
        data.append("recipeInfo", JSON.stringify(element))
      });
      data.append("author", "Crystal Admin")

      server.post("/nutrition/recipe", data, {
        headers: {
          token: localStorage.token,
        }
      })
        .then(response => {
          this.$store.commit("SET_SUCCESS", `Success add data recipe ${response.data.title}`)
          this.$router.push({ name: "Recipe" })
        })
        .catch(err => {
          this.$store.commit("SET_ERROR", err.response.data.errors.message);
        })
        .finally(() => this.d = !this.buttonLoading)
    },
  },
};
</script>

<style></style>
