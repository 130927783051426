<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center justify-space-around">
        <v-col
        cols="3"
        >
            <h2>Invoice of Payment</h2>
        </v-col>
        <v-col cols="3">
            <span>Customer Balance: Rp. 100.000.000</span>
        </v-col>
        <v-col
        cols="6">
            <v-row class="justify-baseline">
                <v-col
                cols="4"
                >   
                    <v-text-field
                        label="Search invoices"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="3" class="fill-height">
                    <v-btn dark dense class="text-capitalize">
                        Import CSV
                    </v-btn>
                </v-col>
                <v-col cols="3" class="fill-height">
                    <v-btn dense dark class="text-capitalize">
                        Add New Orders
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="12" class="crystal-admin-form">       
            <v-row class="align-center">
                <v-col cols="2">
                    <label>Choose Payment</label>
                </v-col>
                <v-col cols="4">
                    <v-row class="justify-space-around">
                        <v-btn
                            color="white"
                            dense
                            class="text-capitalize"
                        >
                            Cash    
                        </v-btn>
                        <v-btn
                            color="white"
                            dense
                            class="text-capitalize"
                        >
                            Credit    
                        </v-btn>
                        <v-btn
                            color="white"
                            dense
                            class="text-capitalize"
                        >
                            Credit Debit
                        </v-btn>
                        <v-btn
                            color="white"
                            dense
                            class="text-capitalize"
                        >
                            e-Check    
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="justify-space-between">
                <v-col cols="9">
                    <v-row>
                        <v-col cols="2">
                            <label>Payment Amt</label>
                            <v-text-field
                                solo
                                dense
                                placeholder="Payment Amount"
                                hide-details
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <label>Date</label>
                            <v-select
                                solo
                                dense
                                label="Choose Date"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <label>Check</label>
                            <v-select
                                solo
                                dense
                                label="Deposit"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <label>Deposit to</label>
                            <v-select
                                solo
                                dense
                                label="Deposit"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <label>A/R Account</label>
                            <v-select
                                solo
                                dense
                                label="Deposit"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <span>EXCHANGE RATE 1 IDR = </span>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around mt-5">
        <v-col cols="6" md="4">
            <div class="pa-3 crystal-admin-form">
                <label>Date</label>
                <v-text-field
                    dense
                    hide-details
                    placeholder="DD/MM/YYYY"
                    append-icon="mdi-calendar"
                >
                </v-text-field>
            </div>
        </v-col>
        <v-col cols="6" md="4">
            <div class="pa-3 crystal-admin-form">
                <label>Customer</label>
                <v-text-field
                    dense
                    hide-details
                    placeholder="Customer"
                >
                </v-text-field>
            </div>
        </v-col>
        <v-col cols="6" md="4">
            <div class="pa-3 crystal-admin-form">
                <label>Channels</label>
                <v-select
                    dense
                    hide-details
                    placeholder="Choose Channel"
                >
                </v-select>
            </div>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <v-data-table
                v-model="selected_deposits"
                :headers="headersDeposit"
                :items="deposits"
                :items-per-page="5"
                class="elevation-1"
                show-select
                selectable-key="receive_from"
                item-key="receive_from"
            >
            </v-data-table>
        </v-col>
    </v-row>
    <v-row class="justify-end">
        <v-col cols="2">
            <v-btn
                dense
                dark
                block
                class="text-capitalize"
            >Save
            </v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'InvoicePayment',
    data() {
        return {
            selected_deposits: [],
            headersDeposit: [
                { text: 'Receive From', value: 'receive_from'},
                { text: 'From Account', value: 'from_account'},
                { text: 'Memo', value: 'memo'},
                { text: 'CHK No.', value: 'check_no'},
                { text: 'PMT. Meth', value: 'pmt'},
                { text: 'Class', value: 'class'},
                { text: 'Amount', value: 'amount'}
            ],
            deposits: [
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                },
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                },
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                },
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                },
                {
                    receive_from: 'Mohammed Salah',
                    from_account: 'Lorem Ipsum',
                    memo: 'Lorem',
                    check_no: 1234,
                    pmt: 131142,
                    class: 123123,
                    amount: 12312
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>