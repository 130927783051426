<template>
  <FaqCategory :faqCategory="faqCategory" @saveFaqCategory="saveFaqCategory" :buttonLoading="buttonLoading" />
</template>

<script>
import server from "../api";
import FaqCategory from "@/components/FaqCategoryForm.vue";

export default {
  name: "AddFaqCategory",
  data() {
    return {
      faqCategory: {
        name: "",
      },
      buttonLoading: false,
    };
  },
  components: {
    FaqCategory,
  },
  methods: {
    saveFaqCategory() {
      this.buttonLoading = !this.buttonLoading
      server.post("/faq_categories", this.faqCategory, {
        headers: {
          token: localStorage.token,
        }
      })
        .then(response => {
          this.$store.commit("SET_SUCCESS", `Success add data faq category ${response.data.name}`)
          this.$router.push({ name: "FaqCategory" })
        })
        .catch(err => {
          this.$store.commit("SET_ERROR", err.response.data.errors.message);
        })
        .finally(() => this.buttonLoading = !this.buttonLoading)
    },
  },
};
</script>

<style></style>
