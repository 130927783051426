<template>
  <CourierForm 
    :courier="courier"
    @saveCourier="saveCourier"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import server from '../api'
import CourierForm from '@/components/CourierForm.vue'

export default {
    name: 'AddCourier',
    data () {
        return {
            courier: {
                status: 1,
                DeliveryMethodId: '',
                code: '',
                deliveryType: '',
                fees: '',
                unitFees: '',
                origins: '',
                destination: '',
                website: ''
            },
            buttonLoading: false
        }
    },
    components: {
        CourierForm
    },
    methods: {
        saveCourier () {
            this.buttonLoading = !this.buttonLoading;
            if(!this.courier.DeliveryMethodId || !this.courier.code || !this.courier.status || !this.courier.website || !this.courier.fees || !this.courier.unitFees || !this.courier.deliveryType || !this.courier.origins || !this.courier.destination) {
                this.$store.commit('SET_ERROR', 'Field tidak boleh kosong')
            } else {
                server.post('/delivery/fees', {
                    DeliveryMethodId: this.courier.DeliveryMethodId,
                    code: this.courier.code,
                    status: this.courier.status,
                    website: this.courier.website,
                    fees: this.courier.fees,
                    unitFees: this.courier.unitFees,
                    deliveryType: this.courier.deliveryType,
                    origins: this.courier.origins,
                    destination: this.courier.destination
                }, {
                    headers: {
                        token: localStorage.token
                    }
                })
                    .then(result => {
                        this.buttonLoading = !this.buttonLoading;
                        this.$router.push({ name: 'Delivery' });
                        this.$store.commit('SET_SUCCESS', `Berhasil Menambahkan Courier dengan Service Name ${result.data.DeliveryMethod.code}`);
                    })
                    .catch(error => {
                        this.buttonLoading = !this.buttonLoading;
                        this.$store.commit('SET_ERROR', error.response.statusText);
                    })
            }
        }
    }
}
</script>

<style>

</style>