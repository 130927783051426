<template>
  <v-dialog
    v-model="detail_internal_stock"
    @click:outside="closeDetail"
  >
    <v-card>
        <v-card-title>
            Internal Stock Transfer Details {{ internal_stock.id }}
            <!-- <v-spacer></v-spacer>   
            <v-chip color="primary">
                {{ order.status }}
            </v-chip> -->
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row class="justify-space-around">
                    <v-col cols="3">
                        <h4 class="text--futura">Received Date</h4><br>
                        <span class="text--futura">
                            {{ formatDate(internal_stock.to_date) }}
                        </span>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="text--futura">From</h4><br>
                        <span class="text--futura" v-if="internal_stock.ShipFromLocation">
                           {{ internal_stock.ShipFromLocation ? internal_stock.ShipFromLocation.LocationName : ''}}
                        </span>
                        <span class="text--futura" v-else-if="internal_stock.Vendor">
                           {{ internal_stock.Vendor ? internal_stock.Vendor.company_name : ''}}
                        </span>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="text--futura">Destination</h4><br>
                        <span class="text--futura" v-if="internal_stock.ShipToLocation">
                           {{ internal_stock.ShipToLocation ? internal_stock.ShipToLocation.LocationName : ''}}
                        </span>
                    </v-col>
                    <v-col cols="3">
                        <h4 class="text--futura">Memo</h4><br>
                        <span class="text--futura">
                           {{ internal_stock.memo ? internal_stock.memo : ''}}
                        </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <h4 class="text--futura">Product</h4>
                    </v-col>
                    <v-col cols="6">
                        <h4 class="text--futura">Quantity</h4>
                    </v-col>
                </v-row>
                <hr>
                <v-row v-for="product in internal_stock.InternalTransferLines" :key="product.id">
                    <v-col cols="6">
                        <span class="text--futura">{{ product.Item.name }}</span>
                    </v-col>
                    <v-col cols="6">
                        <span class="text--futura">{{ product.qty }}</span>
                    </v-col>
                </v-row>
                <hr>
            </v-container>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
    name: 'DetailInternalStock',
    props: {
        detail_internal_stock: {
            type: Boolean,
            default: false
        },
        internal_stock: {
            type: Object,
        }
    },
    methods: {
        closeDetail () {
            this.$emit('closeDetail');
        }
    }
}
</script>

<style>

</style>