<template>
    <div>
        <v-alert
            dense
            text
            type="success"
            v-if="successMessage"
        >
            {{ successMessage }}
        </v-alert>
        <v-alert
            dense
            text
            type="error"
            v-else-if="errorMessage"
        >
            {{ errorMessage }}
        </v-alert>
    </div>
</template>

<script>
export default {
    name: 'Alert',
    computed: {
        successMessage () {
            return this.$store.state.successMessage
        },
        errorMessage () {
            return this.$store.state.errorMessage
        }
    }
}
</script>

<style>

</style>