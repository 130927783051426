<template>
  <v-container fluid class="admin-page">
      <v-row align="center" justify="space-around">
        <v-col
            cols="6"
        >
            <h2>Samples</h2>
        </v-col>
        <v-col
        cols="6">
            <v-row>
                <v-col
                cols="5"
                >   
                    <v-text-field
                        label="Search Sample, Company Name"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                        v-model="filterSample"
                        @keydown.enter="searchSample"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4">
                    <router-link to="/dashboard/add/sample">
                        <v-btn class="text--futura text--white text-capitalize" dark block>
                            Add New Sample
                        </v-btn>
                    </router-link>
                </v-col>    
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="3">
            <div class="pa-3 crystal-admin-form">
                <label>Shipment Date</label>
                <v-row>
                        <v-col
                        cols="12"
                        >
                            <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :return-value.sync="filterDate"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filterDateText"
                                    label="Shipment Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    append-icon="mdi-calendar"
                                    hide-details="auto"
                                    clearable
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filterDate"
                                    no-title
                                    scrollable
                                    range
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu2 = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu2.save(filterDate)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
            </div>     
        </v-col>
        <v-col cols="3">
            <div class="pa-3 crystal-admin-form">
                <label>Ship From</label>
                <v-select
                    label="Choose Warehouse"
                    :items="warehouses"
                    item-text="LocationName"
                    item-value="id"
                    v-model="filterWarehouse"
                    clearable
                >
                </v-select>
            </div>    
        </v-col>
        <v-col cols="3">
            <div class="pa-3 crystal-admin-form">    
                <label>Purpose</label>
                <v-select
                    label="Choose Purpose"
                    :items="purposes"
                    v-model="filterPurpose"
                    clearable
                >
                </v-select>
            </div>       
        </v-col>
        <v-col cols="3">
            <div class="pa-3 crystal-admin-form">    
                <label>Status</label>    
                <v-select
                    label="Choose Shipment Status"
                    :items="shipment_statuses"
                    item-text="label" 
                    item-value="value"
                    v-model="filterShipStatus"
                    clearable
                >
                </v-select>
            </div>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <Alert />
        <v-col
            cols="12"
        >
            <v-data-table
                :headers="headers"
                :items="samples"
                :server-items-length="totalSamples"
                :options="pagination"
                @update:options="paginate"
                :footer-props="{
                    'items-per-page-options': listSize
                }"
                @update:items-per-page="getItemPerPage"
                class="elevation-1"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                    >
                        {{item.status}}
                    </v-chip>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    <td>{{ formatDate(item.createdAt) }}</td>
                </template>
                <template v-slot:[`item.customer`]="{ item }">
                    <td v-if="item.Customer">
                        {{ item.Customer.fullName }}
                    </td>
                    <td v-else-if="item.Vendor">
                        {{ item.Vendor.company_name }}
                    </td>
                </template>
                <template v-slot:[`item.ship_from`]="{ item }">
                    {{ item.ShipFromLocation ? item.ShipFromLocation.LocationName : "-" }}
                </template>
                <template v-slot:[`item.contact`]="{ item }">
                    <td v-if="item.Customer">
                        {{ item.Customer.CustomerContact.contactNo }}
                    </td>
                    <td v-if="item.Vendor">
                        {{ item.Vendor.VendorContact.phone_number }}
                    </td>
                </template>
                <template v-slot:[`item.ship_by`]="{ item }">
                    {{ item.DeliveryMethod ? item.DeliveryMethod.CourierCompany : "-" }}
                </template>
                <template v-slot:[`item.purpose`]="{ item }">
                    {{ item.purpose ? capitalizeFirstLetter(item.purpose) : "-" }}
                </template>
                <template v-slot:[`item.products`]="{ item }">
                    <span v-for="(product, index) in item.SampleLine.items.slice(0, 3)" :key="index">
                        {{ product ? (product.Item ? product.Item.name : '') : "" }}{{` (x${product ? product.qty : ""})` }}{{ index === 2 ? `...${item.SampleLine.items.length - 3} more` : index === item.SampleLine.items.length-1 ? '' : ',' }}
                    </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small text @click="getDetailOrder(item)">mdi-eye</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <DetailSample
        :order="selectedOrder"
        :detail_order="detailOrder"
        :detail_type="detail_type"
        @updateSample="updateSample"
        @closeDetail="closeDetail"
    />
  </v-container>
</template>

<script>
import DetailSample from '@/components/DetailSample.vue'
import Alert from '@/components/Alert.vue'
import dayjs from 'dayjs'
import server from '../api'

export default {
    name: 'Sample',
    components: {
        DetailSample,
        Alert
    },
    data () {
        return {
            detailOrder: false,
            detail_type: {
                text: 'Sample',
                value: 'sample'
            },
            selectedOrder: {},
            filterDate: [],
            filterWarehouse: +this.$route.query.ship_from || '',
            filterPurpose: this.$route.query.purpose || '',
            filterShipStatus: this.$route.query.ship_status || '',
            filterSample: this.$route.query.sample || '',
            menu2: false,
            headers: [
                { text: 'Date', value: 'date'},
                { text: 'Recipient', value: 'customer'},
                { text: 'Product', value: 'products'},
                { text: 'Contact', value: 'contact' },
                { text: 'Ship From', value: 'ship_from'},
                { text: 'Ship By', value: 'ship_by' },
                { text: 'Purpose', value: 'purpose'},
                { text: 'Status', value: 'ShipStatus'},
                { text: 'Action', value: 'actions'}
            ],
            listSize: [15, 25, 50, 100, -1],
            pagination: {
                descending: !!this.$route.query.desc,
                itemsPerPage: +this.$route.query.per_page || 15,
                page: +this.$route.query.page || 1,
                totalItems: this.totalOrders,
            },
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to);
        next();
    },
    watch: {
        filterDate (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.start_date = val[0] ? val[0] : '';
            obj.end_date = val[1] ? val[1] : '';
            if (!obj.start_date) delete obj.start_date;
            if (!obj.end_date) delete obj.end_date;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterWarehouse (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.ship_from = val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterPurpose (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.purpose = val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterShipStatus (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.ship_status = val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterSample (val) {
            if (!val) {
                this.searchSample();
            }
        }
    },
    methods: {
        getDetailOrder(item) {
            this.selectedOrder = item;setInterval;
            this.detailOrder = true;
        },
        formatDate (date) {
            return dayjs(date).format('DD/MM/YYYY');
        },
        closeDetail () {
            this.detailOrder = !this.detailOrder;
        },
        fetchData (route) {
            this.$store.dispatch('getSamples', route.query);
        },
        searchSample () {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.sample = this.filterSample;
            if (this.filterSample === '') delete obj.sample;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        updateSample (id) {
            server.put(`/samples/${id}`, {
                ShipFromLocationId: this.selectedOrder.ShipFromLocationId,
                DeliveryMethodId: this.selectedOrder.DeliveryMethodId,
                ShiptoAddress: this.selectedOrder.ShiptoAddress1,
                OrderStatus: this.selectedOrder.status,
                ShipStatus: this.selectedOrder.ShipStatus,
                tracking_code: this.selectedOrder.tracking_code,
                items: this.selectedOrder.SampleLine.items
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.$store.commit('SET_SUCCESS', result.data.message);
                    this.detailOrder = !this.detailOrder;
                })
                .catch(error => {
                    this.$store.commit('SET_ERROR', error.response.data);
                });
        },
        getItemPerPage(val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            this.pagination.itemsPerPage = +val;
            obj.per_page = +val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        paginate (val) {
            // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            if (val.itemsPerPage !== this.listSize[0]) obj.per_page = val.itemsPerPage;
            if (val.descending) obj.desc = 'true';
            else delete obj.desc;
            obj.orderby = val.sortBy;
            obj.page = val.page;
            obj.per_page = val.itemsPerPage;
            // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
            let same = true;
            for (let key in query) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            // to handle the case when a KEY exists in OBJ but not in query
            for (let key in obj) {
                if (query[key] != obj[key]) {
                    same = false;
                    break;
                }
            }
            if (same) this.fetchData(); // page has been manually reloaded in the browser
            else {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: obj
                });
            }
        },
    },
    computed: {
        samples () {
            return this.$store.state.samples;
        },
        products () {
            return this.$store.state.products;
        },
        totalSamples () {
            return this.$store.state.totalSamples;
        },
        warehouses () {
            return this.$store.state.warehouses;
        },
        purposes () {
            return this.$store.state.purposes;
        },
        shipment_statuses () {
            return this.$store.state.shipment_statuses;
        },
        filterDateText: {
            get () {
                return this.filterDate.join(' - ');
            },
            set () {
                this.filterDate = [];
            }
        },
    },
    created () {
        this.$store.dispatch('getSamples', this.$route.query);
        this.$store.dispatch('getProducts');
        this.$store.dispatch('getWarehouses');
    }
}
</script>

<style>

</style>