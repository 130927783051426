var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Returns")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"label":"Search Return","append-icon":"mdi-magnify","solo":"","clearable":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"2"}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-history")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/dashboard/add/return"}},[_c('v-btn',{staticClass:"text--futura text--white text-capitalize",attrs:{"dark":"","block":""}},[_vm._v(" Add New Return ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"justify-space-around"}),_c('v-row',{staticClass:"mt-2"},[_c('Alert'),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.returns,"options":_vm.pagination,"footer-props":{
                  'items-per-page-options': _vm.listSize
              }},on:{"update:options":_vm.paginate},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.products",fn:function(ref){
              var item = ref.item;
return _vm._l((item.items.slice(0, 3)),function(product,index){return _c('span',{key:product.id},[_vm._v(" "+_vm._s(product.Item ? product.Item.name : '')+_vm._s((" (x" + (product.qty) + ")"))+_vm._s(index === 2 && (item.items.length - 3) !== 0 ? ("..." + (item.items.length - 3) + " more") : index === item.items.length-1 ? '' : ',')+" ")])})}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.getDetailOrder(item)}}},[_vm._v("mdi-eye")]),_c('v-icon',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('DetailReturn',{attrs:{"order":_vm.selectedOrder,"detail_return":_vm.detail_return},on:{"closeDetail":_vm.closeDetail}}),_c('DeleteDialog',{attrs:{"message":("Remove Return " + (_vm.selectedOrder.id) + " ?"),"item":_vm.selectedOrder,"showDelete":_vm.showDelete},on:{"deleteItem":_vm.deleteReturn,"closeDialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }