<template>
  <v-container fluid class="admin-page">
    <v-row class="justify-space-between">
      <v-col cols="7">
        <h2>{{ type.text }} Recipe</h2>
      </v-col>
      <v-col cols="2">
      </v-col>
    </v-row>
    <v-row justify="center">
      <Alert />
    </v-row>
    <v-row class="justify-end">
      <v-col cols="2">
        <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveRecipe()"
          :loading="buttonLoading" :disabled="buttonLoading">
          {{ type.value == "new" ? "Save" : "Update" }}
          <template v-slot:loader>
            <v-progress-circular indeterminate color="black"></v-progress-circular>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="crystal-admin-form justify-space-around pt-5">
      <v-col cols="10">
        <label>Recipe Name</label>
        <v-text-field solo placeholder="Enter Recipe Name" v-model="recipe.recipe_name"></v-text-field>
        <label>Category</label>
        <v-select solo :items="recipeCategory" item-text="name" item-value="name" label="Choose Category"
          append-icon="mdi-magnify" v-model="recipe.recipeCategoryName"></v-select>
        <label>Description</label>
        <vue-editor v-model="recipe.description" :editorToolbar="customToolbar"></vue-editor>
        <label>How many serving</label>
        <v-text-field solo placeholder="Enter Recipe Title" v-model="recipe.servingSize"></v-text-field>
        <label>Cooking Type</label>
        <v-autocomplete
        v-model="recipe.cookingMethod"
  label="Cooking Method"
  item-text="name"
  item-title="name"
  item-value="cooking_style"
  :items="cookingType"
></v-autocomplete>
<label>Age Range</label>
        <v-autocomplete
        v-model="recipe.ageRange"
  label="Age Range"
  item-text="text"
  item-title="value"
  item-value="value"
  :items="ageRange"
></v-autocomplete>
        <label>How long to cook this recipe ?</label>
        <v-text-field solo placeholder="Enter Recipe Title" v-model="recipe.servingTime"></v-text-field>
        <label>Ingredient</label>
        <v-autocomplete
        v-model="recipe.recipeInfo"
  label="Ingredients"
  item-text="Ingredient_name"
  item-title="Ingredient_name"
  return-object
  :items="ingredient"
  multiple
></v-autocomplete>
<label>Ingredient Amount</label>
  <div class="mt-3 ms-3">
    <div v-for="item in recipe.recipeInfo" v-bind:key="item.id">
    <label>{{item.Ingredient_name}} amount :</label>
    <v-text-field solo placeholder="Enter amount" v-model="item.amount" suffix="gram"></v-text-field>
  </div>
  </div>
        <!-- <v-text-field solo placeholder="Enter Recipe Title" v-model="recipe.ingredients"></v-text-field> -->
        <!-- <VueMultiselect v-model="recipe.serving_time" :options="ingredient"></VueMultiselect> -->
        <label>Instructions</label>
        <vue-editor v-model="recipe.instructions" :editorToolbar="customToolbar"></vue-editor>
        <label>Notes</label>
        <vue-editor v-model="recipe.notes" :editorToolbar="customToolbar"></vue-editor>
        <label>Recipe Images</label>
        <!-- <v-text-field solo placeholder="Enter Recipe Title" v-model="recipe.title"></v-text-field> -->
        <picture-input 
          ref="pictureInput"
          width="200" 
          height="200"
          button-class="btn"
          :prefill="imageUrl"
          @change="onChange">
        </picture-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alert from "@/components/Alert.vue";
import PictureInput from 'vue-picture-input'
import { VueEditor } from "vue2-editor";

export default {
  name: "RecipeForm",
  components: {
    Alert,
    VueEditor,
    PictureInput
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        // ["blockquote", "code-block"],
      ],
    }
  },
  props: {
    recipe: {
      type: Object
    },
    type: {
      type: Object,
      default: () => {
        return {
          text: "Form",
          value: "new",
        };
      },
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveRecipe() {
      this.$emit("saveRecipe");
    },
    onChange() {
      this.recipe.image = this.$refs.pictureInput.file
    },
  },
  computed: {
    recipeCategory() {
      return this.$store.state.recipeCategory;
    },
    ingredient() {
      return this.$store.state.ingredient;
    },
    cookingType() {
      return this.$store.state.cookingType;
    },
    ageRange() {
      return this.$store.state.ageRange;
    },
    imageUrl() {
      return `${process.env.VUE_APP_API_URL}/${this.recipe.image_url}`;
    },
    completeIngredient() {
      return this.$store.state.ingredient.filter(i => this.recipe.recipeInfo.includes(i.id))
    }
  },
  created() {
    this.$store.dispatch("getIngredient");
    this.$store.dispatch("getRecipeCategory");
    this.$store.dispatch("getCookingType");
  },
  mounted() {
    this.$refs.pictureInput.$el.addEventListener('change', this.onChange, false);
  }
};
</script>

<style></style>
