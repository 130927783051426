var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Testers")])]),_c('v-col',{attrs:{"cols":"6"}})],1),_c('v-row',{staticClass:"justify-space-start"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"pa-3 crystal-admin-form"},[_c('label',[_vm._v("Status")]),_c('v-select',{attrs:{"label":"Choose Status","items":_vm.tester_statuses,"clearable":""},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('Alert'),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.testers,"server-items-length":_vm.totalTesters,"options":_vm.pagination,"footer-props":{
                    'items-per-page-options': _vm.listSize
                }},on:{"update:options":_vm.paginate,"update:items-per-page":_vm.getItemPerPage},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.status))+" ")]}},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.customer",fn:function(ref){
                var item = ref.item;
return [(item.Customer)?_c('td',[_vm._v(" "+_vm._s(item.Customer.fullName)+" ")]):(item.Vendor)?_c('td',[_vm._v(" "+_vm._s(item.Vendor.company_name)+" ")]):_vm._e()]}},{key:"item.ship_from",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.ShipFromLocation.LocationName)+" ")]}},{key:"item.contact",fn:function(ref){
                var item = ref.item;
return [(item.Customer)?_c('td',[_vm._v(" "+_vm._s(item.Customer.CustomerContact.contactNo)+" ")]):_vm._e(),(item.Vendor)?_c('td',[_vm._v(" "+_vm._s(item.Vendor.VendorContact.phone_number)+" ")]):_vm._e()]}},{key:"item.ship_by",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.DeliveryMethod.CourierCompany)+" ")]}},{key:"item.purpose",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.purpose))+" ")]}},{key:"item.products",fn:function(ref){
                var item = ref.item;
return _vm._l((item.TesterLine.items.slice(0, 3)),function(product,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(product ? (product.Item ? product.Item.name : '') : "")+_vm._s((" (x" + (product ? product.qty : "") + ")"))+_vm._s(index === 2 ? ("..." + (item.TesterLine.items.length - 3) + " more") : index === item.TesterLine.items.length - 1 ? '' : ',')+" ")])})}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.getDetailOrder(item)}}},[_c('v-icon',{attrs:{"small":"","text":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)})],1)],1),_c('DetailTester',{attrs:{"order":_vm.selectedOrder,"detail_order":_vm.detailOrder,"detail_type":_vm.detail_type},on:{"updateTester":_vm.updateTester,"closeDetail":_vm.closeDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }