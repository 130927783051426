<template>
  <OrderForm 
    :order="order"
    @saveOrder="updateOrder"
    :formType="formType"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import OrderForm from '@/components/OrderForm.vue'
import server from '../api'

export default {
    name: 'EditOrder',
    components: {
        OrderForm
    },
    data () {
        return {
            formType: {
                text: 'Edit',
                value: 'edit'
            },
            buttonLoading: false
        }
    },
    methods: {
        updateOrder(grand_total) {
            this.buttonLoading = !this.buttonLoading;
            this.buttonLoading = !this.buttonLoading;
            let firstName;
            let middleName;
            let lastName;
            if(this.order.Customer.fullName) {
                let name = this.order.Customer.fullName.split(' ');
                firstName = name[0] ? name[0] : '';
                middleName = name[1] ? name[1] : '';
                lastName = name[2] ? name[2] : '';
            }
            server.post('/customers', {
                firstName,
                middleName,
                lastName,
                city_id: this.order.city_id,
                province_id: this.order.province_id,
                postal_code: this.order.postal_code,
                contactNo: `${this.order.Customer.CustomerContact.contactNo}`,
                addressLine1: this.order.ShiptoAddress,
            }, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(results => {
                    return server.put(`/orders/${this.$route.params.id}`, {
                            CustomerId: results.data.Customer.id,
                            ShipFromLocationId: this.order.ShipFromLocationId,
                            DeliveryMethodId: this.order.DeliveryMethodId,
                            ShiptoAddress: this.order.ShiptoAddress,
                            OrderStatus: this.order.OrderStatus,
                            ShipStatus: this.order.ShipStatus,
                            items: this.order.OrderLine.items,
                            sales_type: this.order.sales_type,
                            customer_type: this.order.customer_type,
                            city_id: this.order.city_id,
                            postal_code: this.order.postal_code,
                            province_id: this.order.province_id,
                            order_date: this.order.order_date,
                            shipment_date: this.order.shipment_date,
                            tax: this.order.tax,
                            delivery_fee: this.order.delivery_fee,
                            grand_total,
                            memo: this.order.memo,
                            invoice_no: this.order.invoice_no
                        }, {
                            headers: {
                                token: localStorage.token
                            }
                        })
                })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil mengubah Order ${result.data.Order.id}`);
                    this.$router.go(-1);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_ERROR', err.response.data.errors.message);
                });
        }
    },
    computed: {
        order () {
            return this.$store.state.order;
        }
    },
    created() {
        this.$store.dispatch('getOrderById', { id: this.$route.params.id });
    }
}
</script>

<style>

</style>