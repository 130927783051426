<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="8"
        >
            <h2>Stock Tracker</h2>
        </v-col>
        <v-col
        cols="4">
            <v-row>
                <v-col
                cols="8"
                >   
                    <v-text-field
                        label="Search Item"
                        append-icon="mdi-magnify"
                        @click:append="searchItem"
                        solo
                        class="shrink"
                        clearable
                        dense
                        v-model="filterItem"
                        @keydown.enter="searchItem"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>   
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="6">
            <div class="pa-3 crystal-admin-form">
                <label>Filter Date</label>
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="filter_date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="filter_date"
                        label="Stock Until"
                        readonly
                        v-bind="attrs"
                        append-icon="mdi-calendar"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="filter_date"
                    no-title
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(filter_date)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-menu>
            </div>
        </v-col>
        <v-col cols="6">
            <div class="pa-3 crystal-admin-form">
                <label>Ship From</label>
                <v-select
                    label="Choose Warehouse"
                    :items="warehouses"
                    item-text="LocationName"
                    item-value="id"
                    v-model="filterWarehouse"
                    clearable
                >
                </v-select>
            </div>    
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <Alert />
        <v-col
            cols="12"
        >
            <v-data-table
                :headers="headers"
                :items="stockCount"
                :items-per-page="5"
                class="elevation-1"
                :loading="tableLoading"
                :footer-props="{
                    'items-per-page-options': listSize,
                }"
                loading-text="Loading Stock Data..."
                :sort-by.sync="sortBy"
            >
                <template v-slot:[`item.RealStock`]="{ item }">
                    <v-row>
                        <v-col cols="3">
                            <v-text-field
                                v-model="item.RealStock"
                                label="Real Stock"
                                hide-details
                                key="item.id"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-btn
                                small
                                color="primary"
                                @click="updateStock(item)"
                                :loading="buttonLoading"
                                :disabled="buttonLoading"
                            >Save</v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import server from '../api'
import Alert from '@/components/Alert.vue'

export default {
    name: 'StockTracker',
    components: {
        Alert
    },
    data () {
        return {
            headers: [
                { text: 'Warehouse', value: 'Warehouse.LocationName'},
                { text: 'Item', value: 'Item.name'},
                { text: 'Current Stock', value: 'CurrentStock'},
                { text: 'Real Stock', value: 'RealStock'},
                { text: 'Pending Stock', value: 'PendingStock'}
            ],
            filter_date: new Date().toISOString().substr(0,10),
            filterWarehouse: +this.$route.query.warehouse,
            filterItem: this.$route.query.item,
            listSize: [15, 25, 50, 100, -1],
            editStock: true,
            menu: false,
            buttonLoading: false,
            sortBy: 'Warehouse.LocationName'
        }
    },
    computed: {
        stockCount () {
            return this.$store.state.stockCount;
        },
        warehouses () {
            return this.$store.state.warehouses;
        },
        tableLoading () {
            return this.$store.state.tableLoading;
        }
    },
    watch: {
        filter_date (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.filter_date = val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        filterWarehouse (val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.warehouse = val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to);
        next();
    },
    methods: {
        getItemPerPage(val) {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            this.pagination.rowsPerPage = +val;
            obj.per_page = +val;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        searchItem () {
            const query = this.$route.query;
            const obj = Object.assign({}, query);
            obj.item = this.filterItem;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: obj
            });
        },
        fetchData (route = this.$route) {
            this.$store.dispatch('getStockCount', route.query);
        },
        updateStock (value) {
            this.buttonLoading = !this.buttonLoading;

            if (!value.RealStock) {
                this.buttonLoading = !this.buttonLoading;
                this.$store.commit('SET_ERROR', `[ERROR] Error saving real stock. Real Stock cannot be empty`);
            } else {
                let items = [
                    {
                        ItemId: value.ItemId,
                        qty: value.RealStock - value.CurrentStock
                    }
                ];
                server.post('/internal_transfer', {
                    from_date: new Date().toISOString().substr(0,10),
                    to_date: new Date().toISOString().substr(0,10),
                    items: JSON.stringify(items),
                    ShipFromLocationId: value.WarehouseId,
                    DeliveryMethodId: 1,
                    type: 'adjustment',
                    status: 1
                }, {
                    headers: {
                        token: localStorage.token
                    }
                })
                    .then(() => {
                        this.buttonLoading = !this.buttonLoading;
                        this.$store.commit('SET_SUCCESS', `Berhasil Update Stock`)
                        this.$store.dispatch('getStockCount', this.$route.query);
                    })
                    .catch(err => {
                        this.buttonLoading = !this.buttonLoading;
                        this.$store.commit('SET_ERROR', `[ERROR] ${err.response.errors}`)
                    })
            }
        }
    },
    created () {
        this.$store.dispatch('getStockCount', this.$route.query);
        this.$store.dispatch('getWarehouses');
    }
}
</script>

<style>

</style>