var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"admin-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center",attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Internal Stock Transfer")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"label":"Search Vendor ID","append-icon":"mdi-magnify","solo":"","clearable":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"2"}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-history")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/dashboard/add/stock"}},[_c('v-btn',{staticClass:"text--futura text--white text-capitalize",attrs:{"dark":"","block":""}},[_vm._v(" Add New Stock ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"justify-start"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"px-3 crystal-admin-form"},[_c('label',[_vm._v("Date")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.filterDate,"transition":"scale-transition","offset-y":"","min-width":"350px"},on:{"update:returnValue":function($event){_vm.filterDate=$event},"update:return-value":function($event){_vm.filterDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Choose Date","readonly":"","append-icon":"mdi-calendar","clearable":"","hide-details":""},model:{value:(_vm.filterDateText),callback:function ($$v) {_vm.filterDateText=$$v},expression:"filterDateText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.filterDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"px-3 crystal-admin-form"},[_c('label',[_vm._v("From - Destination")]),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"placeholder":"From","clearable":""},model:{value:(_vm.filterFrom),callback:function ($$v) {_vm.filterFrom=$$v},expression:"filterFrom"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"placeholder":"To","clearable":""},model:{value:(_vm.filterTo),callback:function ($$v) {_vm.filterTo=$$v},expression:"filterTo"}})],1)],1)],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"px-3 crystal-admin-form",attrs:{"fill-height":""}},[_c('label',[_vm._v("Type")]),_c('v-select',{attrs:{"placeholder":"Type","items":_vm.types},model:{value:(_vm.filterType),callback:function ($$v) {_vm.filterType=$$v},expression:"filterType"}})],1)])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('Alert'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.internalTransfers,"options":_vm.pagination,"footer-props":{
                  'items-per-page-options': _vm.listSize
              }},on:{"update:options":_vm.paginate},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-switch',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.from_date",fn:function(ref){
              var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDate(item.from_date)))])]}},{key:"item.from",fn:function(ref){
              var item = ref.item;
return [(item.Vendor)?_c('td',[_vm._v(_vm._s(item.Vendor.company_name))]):(item.ShipFromLocation)?_c('td',[_vm._v(_vm._s(item.ShipFromLocation ? item.ShipFromLocation.LocationName : ''))]):_vm._e()]}},{key:"item.qty",fn:function(ref){
              var item = ref.item;
return _vm._l((item.InternalTransferLines.slice(0, 3)),function(product,index){return _c('span',{key:product.id},[_vm._v(" "+_vm._s(product.Item ? product.Item.name : '')+_vm._s((" (x" + (product.qty) + ")"))+_vm._s(index === 2 ? ("..." + (item.InternalTransferLines.length - 3) + " more") : index === item.InternalTransferLines.length-1 ? '' : ',')+" ")])})}},{key:"item.destination",fn:function(ref){
              var item = ref.item;
return [(item.ToVendor)?_c('td',[_vm._v(_vm._s(item.ToVendor.company_name))]):(item.ShipToLocation)?_c('td',[_vm._v(_vm._s(item.ShipToLocation ? item.ShipToLocation.LocationName : ''))]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.type",fn:function(ref){
              var item = ref.item;
return [(item.type == 'inter_warehouse')?_c('td',[_vm._v("Inter Warehouse")]):(item.type == 'adjustment')?_c('td',[_vm._v("Adjustment")]):(item.type == 'purchases')?_c('td',[_vm._v("Purchases")]):(item.type == 'purchase_return')?_c('td',[_vm._v("Purchase Return")]):_vm._e()]}},{key:"item.memo",fn:function(ref){
              var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.InternalTransferLines[0] ? item.InternalTransferLines[0].memo : 'Lorem Ipsum'))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.getDetailInternalStock(item)}}},[_vm._v("mdi-eye")]),_c('v-icon',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('DeleteDialog',{attrs:{"message":("Remove Internal Stock " + (_vm.internal_transfer.id) + " ?"),"item":_vm.internal_transfer,"showDelete":_vm.showDelete},on:{"deleteItem":_vm.deleteStock,"closeDialog":_vm.closeDialog}}),_c('DetailInternalStock',{attrs:{"internal_stock":_vm.internal_transfer,"detail_internal_stock":_vm.detail_internal_stock},on:{"closeDetail":_vm.closeDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }