<template>
  <v-container fluid class="admin-page">
    <v-row class="align-center" justify="space-around">
      <v-col cols="6">
        <h2>Retail Partner Locations</h2>
      </v-col>
      <v-col cols="6">
        <v-row>
          <!-- <v-col cols="6">
            <v-text-field
              label="Search Customer"
              append-icon="mdi-magnify"
              solo
              class="shrink"
              clearable
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" class="fill-height">
            <v-btn>
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </v-col> -->
          <v-col cols="4" offset="7">
            <router-link to="/dashboard/add/retail-partner">
              <v-btn
                class="text--futura text--white text-capitalize"
                dark
                block
              >
                Add Retail Location
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <Alert />
        <v-data-table
          :loading="tableLoading"
          :headers="headers"
          :items="retails"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small text @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DeleteDialog
      :message="`Remove Retailer Location ${retail.displayName} ?`"
      :item="retail"
      :showDelete="showDelete"
      @deleteItem="deleteRetail"
      @closeDialog="closeDialog"
    />
  </v-container>
</template>

<script>
import Alert from "../components/Alert.vue";
import DeleteDialog from "../components/DeleteDialog.vue";
import server from "../api";
import dayjs from "dayjs";

export default {
  name: "RetailPartner",
  components: {
    Alert,
    DeleteDialog,
  },
  data() {
    return {
      headers: [
        { text: "Customer", value: "Customer.fullName" },
        { text: "Name Location", value: "displayName" },
        { text: "Mobile Number", value: "displayPhone" },
        { text: "Website", value: "website" },
        { text: "Action", value: "actions" },
      ],
      showDelete: false,
      retail: {
        Customer: {}
      },
      valid_period: new Date().toISOString().substr(0, 10),
      menu: false,
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    showDeleteDialog(retail) {
      this.retail = retail;
      this.showDelete = true;
    },
    closeDialog() {
      this.showDelete = false;
    },
    deleteRetail(id) {
      server
        .delete(`/retailer_locations/${id}`, {
          headers: {
            token: localStorage.token,
          },
        })
        .then((result) => {
          this.showDelete = false;
          this.$store.dispatch("getAllRetailLocations");
          this.$store.commit(
            "SET_SUCCESS",
            `Berhasil Menghapus Reatiler Location ${result.data.Retailer.displayName}`
          );
        })
        .catch((err) => {
          this.$store.commit("SET_ERROR", err.data.response.errors[0].message);
        });
    },
    editItem(item) {
      this.retail = item;
      this.$router.push({
        path: `/dashboard/edit/retail-partner/${this.retail.id}`,
      });
    },
  },
  computed: {
    retails() {
      return this.$store.state.retailPartner;
    },
    tableLoading() {
      return this.$store.state.tableLoading;
    },
  },
  created() {
    this.$store.dispatch("getAllRetailLocations");
  },
};
</script>

<style></style>
