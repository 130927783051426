<template>
  <Faq :faq="faq" @saveFaq="saveFaq" :buttonLoading="buttonLoading" />
</template>

<script>
import server from "../api";
import Faq from "@/components/FaqForm.vue";

export default {
  name: "EditFaq",
  data() {
    return {
      buttonLoading: false,
    };
  },
  components: {
    Faq,
  },
  methods: {
    saveFaq() {
      this.buttonLoading = !this.buttonLoading
      server.put(`/faqs/${this.faq.id}`, this.faq, {
        headers: {
          token: localStorage.token,
        }
      })
        .then(response => {
          this.$store.commit("SET_SUCCESS", `Success edit data faq ${response.data.title}`)
          this.$router.push({ name: "Faq" })
        })
        .catch(err => {
          this.$store.commit("SET_ERROR", err.response.data.errors.message);
        })
        .finally(() => this.buttonLoading = !this.buttonLoading)
    },
  },
  computed: {
    faq() {
      return this.$store.state.detailFaq
    }
  },
  created() {
    this.$store.dispatch('getFaqById', { id: this.$route.params.id });
  }
};
</script>

<style></style>
