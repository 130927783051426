<template>
  <v-container fluid class="admin-page">
      <v-row class="align-center">
        <v-col
        cols="8"
        >
            <h2>Bill Payment</h2>
        </v-col>
        <v-col
        cols="4">
            <v-row>
                <v-col
                cols="8"
                >   
                    <v-text-field
                        label="Search product, destination"
                        append-icon="mdi-magnify"
                        solo
                        class="shrink"
                        clearable
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="fill-height">
                    <v-btn>
                        <v-icon>mdi-history</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class="justify-space-around">
        <v-col cols="12" class="crystal-admin-form">       
            <label>Filter By Vendor</label>
            <v-select
                label="Choose Vendor"
            />
        </v-col>
    </v-row>
    <v-row class="mt-2 justify-space-between">
        <v-col cols="6">
            <div class="pa-3 crystal-admin-form" fill-height>
                <h5 class="text--grey">Discount & Credit Information For Highlighted Bill</h5>
                <v-row class="justify-space-between">
                    <v-col cols="4">
                        <small>Vendor</small><br>
                        <small>Bill Ref No</small><br>
                        <v-btn
                            dark
                            class="text-capitalize"
                        >Go to bill
                        </v-btn>
                    </v-col>
                    <v-col cols="4">
                        <small>Terms</small><br>
                        <small>Suggest Disc. IDR</small><br>
                        <v-btn
                            dark
                            class="text-capitalize"
                        >Set Discount
                        </v-btn>
                    </v-col>
                    <v-col cols="4">
                        <small>Num. of credits</small><br>
                        <small>Total Credits Available</small><br>
                        <v-btn
                            dark
                            class="text-capitalize"
                        >Set Credits
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="6">
            <div class="pa-3 crystal-admin-form">
                <h5 class="text--grey">Payment</h5>
                <v-row class="align-end">
                    <v-col cols="4" class="px-0 pl-2 pr-2">
                        <small>Date</small><br>
                        <v-text-field
                            solo
                            placeholder="Choose Date"
                            dense
                            hide-details="auto"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="px-0 pr-2">
                        <small>Method</small><br>
                        <v-select
                            solo
                            label="Check"
                            dense
                            hide-details="auto"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="4" class="px-0 pr-2">
                        <small>Account</small><br>
                        <small>Ending Balance:</small><br>
                        <small><b>IDR 250.000.000,00</b></small><br>
                        <v-select
                            solo
                            label="Check"
                            dense
                            hide-details="auto"
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <small>Exchange Rate 1 IDR = </small>
                        <v-text-field
                            placeholder="1"
                            solo
                            dense
                            class="shrink"
                            hide-details="auto"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox
                            label="To be printed"
                        >
                        </v-checkbox>
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox
                            label="Assign check number"
                        >
                        </v-checkbox>
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
    <v-row class="mt-2">
        <v-col
            cols="12"
        >
            <v-data-table
                v-model="selected_bills"
                :headers="headers"
                :items="bills"
                :items-per-page="5"
                class="elevation-1"
                show-select
                selectable-key="name"
                item-key="name"
            >
                <template v-slot:[`item.amt_due`]="{ item }">
                    {{ formatHarga(item.amt_due) }}
                </template>
                <template v-slot:[`item.disc_used`]="{ item }">
                    <a href="#" @click.prevent="showModal(item, 'Discount')">{{ formatHarga(item.disc_used) }}</a>
                </template>
                <template v-slot:[`item.credit_used`]="{ item }">
                    <a href="" @click.prevent="showModal(item, 'Credits')">{{ formatHarga(item.credit_used) }}</a>
                </template>
                <template v-slot:[`item.pay_amount`]="{ item }">
                    {{ formatHarga(item.pay_amount) }}
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <v-row class="justify-end">
        <v-col cols="2">
            <v-btn
                dense
                dark
                class="text-capitalize"
            >Pay Selected Bills
            </v-btn>
        </v-col>
    </v-row>
    <BillDialog
     :showBillDialog="showBillDialog"
     :type="billType"
     :bill="bill"
     @closeModal="closeModal"
    >
        <template v-slot:bill-dialog-content v-if="billType == 'Credits'">
            <v-col cols="12">
                Your Available Credits
            </v-col>
            <v-col cols="12">
                <v-data-table
                    v-model="selected_credits"
                    :headers="credit_headers"
                    :items="credits"
                    :items-per-page="5"
                    class="elevation-1"
                    show-select
                    selectable-key="id"
                    item-key="id"
                >
                </v-data-table>
            </v-col>
        </template>
    </BillDialog>
  </v-container>
</template>

<script>
import BillDialog from '@/components/BillDialog.vue'

export default {
    name: 'BillPayment',
    components: {
        BillDialog
    },
    data () {
        return {
            selected_bills: [],
            selected_credits: [],
            bill: {},
            billType: '',
            showBillDialog: false,
            credit_headers: [
                { text: 'Date', value: 'date'},
                { text: 'Ref No', value: 'ref_no'},
                { text: 'Credit Amt', value: 'credit_amt'},
                { text: 'Amt to Use', value: 'amt_use'},
                { text: 'Balance', value: 'balance'}
            ],
            credits: [
                {
                    id: 1,
                    date: '05/10/2020',
                    ref_no: '123456',
                    credit_amt: '1000000',
                    amt_use: '100000',
                    balance: '10000'
                },
                {
                    id: 2,
                    date: '05/10/2020',
                    ref_no: '123456',
                    credit_amt: '1000000',
                    amt_use: '100000',
                    balance: '10000'
                },
                {
                    id: 3,
                    date: '05/10/2020',
                    ref_no: '123456',
                    credit_amt: '1000000',
                    amt_use: '100000',
                    balance: '10000'
                },
                {
                    id: 4,
                    date: '05/10/2020',
                    ref_no: '123456',
                    credit_amt: '1000000',
                    amt_use: '100000',
                    balance: '10000'
                }
            ],
            headers: [
                { text: 'Date Due', value: 'name'},
                { text: 'Vendor', value: 'vendor'},
                { text: 'Ref. No', value: 'ref_no'},
                { text: 'Disc. Date', value: 'disc_date'},
                { text: 'Amt. Due', value: 'amt_due'},
                { text: 'Disc. Used', value: 'disc_used' },
                { text: "Credit Used", value: 'credit_used'},
                { text: "Amt. to Pay", value: 'pay_amount'}
            ],
            bills: [
                { 
                    name: 'Salah',
                    vendor: 'Food Powder - Brown Anchovy',
                    ref_no: '0123456789',
                    disc_date: '05/10/2020',
                    amt_due: 100000,
                    disc_used: 100000,
                    credit_used: 100000,
                    pay_amount: 100000
                },
                { 
                    name: 'Mane',
                    vendor: 'Food Powder - Brown Anchovy',
                    ref_no: '0123456789',
                    disc_date: '05/10/2020',
                    amt_due: 100000,
                    disc_used: 100000,
                    credit_used: 100000,
                    pay_amount: 100000
                },
                { 
                    name: 'Mbappe',
                    vendor: 'Food Powder - Brown Anchovy',
                    ref_no: '0123456789',
                    disc_date: '05/10/2020',
                    amt_due: 100000,
                    disc_used: 100000,
                    credit_used: 100000,
                    pay_amount: 100000
                },
                { 
                    name: 'Lina',
                    vendor: 'Food Powder - Brown Anchovy',
                    ref_no: '0123456789',
                    disc_date: '05/10/2020',
                    amt_due: 100000,
                    disc_used: 100000,
                    credit_used: 100000,
                    pay_amount: 100000
                },
                { 
                    name: 'Inverse',
                    vendor: 'Food Powder - Brown Anchovy',
                    ref_no: '0123456789',
                    disc_date: '05/10/2020',
                    amt_due: 100000,
                    disc_used: 100000,
                    credit_used: 100000,
                    pay_amount: 100000
                },
            ]
        }
    },
    methods: {
        showModal(bill, type) {
            this.bill = bill;
            this.billType = type;
            this.showBillDialog = true;
        },
        closeModal() {
            this.showBillDialog = false;
        }
    }
}
</script>

<style>

</style>