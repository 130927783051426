<template>
  <v-container fluid class="admin-page">
    <v-row class="justify-space-between">
      <v-col cols="7">
        <h2>{{ type.text }} Product</h2>
      </v-col>
      <v-col cols="2">
        <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveProduct()"
          :loading="buttonLoading" :disabled="buttonLoading">
          {{ type.value == "new" ? "Save" : "Update" }}
        </v-btn>
      </v-col>
    </v-row>
    <Alert />
    <v-row class="crystal-admin-form justify-space-around">
      <v-col cols="5">
        <label>Product Name (Official): </label>
        <v-text-field solo placeholder="Product Name" v-model="item.name"></v-text-field>
        <label>Slug Product: </label>
        <v-text-field solo placeholder="Slug Name e.g. dried-white-anchovy-80g" v-model="item.slug"></v-text-field>
        <label>Script Tag: </label>
        <v-textarea solo placeholder="Script Tag" v-model="item.tag_script"></v-textarea>
        <label>Meta Title: </label>
        <v-text-field solo placeholder="Slug Name e.g. Udang Ebi Kering non-MSG Bebas Formalin"
          v-model="item.meta_title"></v-text-field>
        <label>eCommerce Name: </label>
        <v-text-field solo placeholder="Product Name" v-model="item.ecommerce_name"></v-text-field>
        <label>WA Name: </label>
        <v-text-field solo placeholder="Product Name" v-model="item.whatsapp_name"></v-text-field>
        <label>SKU: </label>
        <v-text-field solo placeholder="Stock Keeping Unit (SKU)" v-model="item.sku"></v-text-field>
        <label>Description</label>
        <v-textarea solo placeholder="Description" v-model="item.description">
        </v-textarea>
        <label>Content Box</label>
        <vue-editor v-model="item.content_box" :editorToolbar="customToolbar"></vue-editor>
        <br />
        <label>Category</label>
        <v-select solo label="Product Category" :items="categories" item-text="name" item-value="id"
          v-model="item.category_id">
        </v-select>
        <label>BPOM MD: </label>
        <v-text-field solo placeholder="BPOM MD" v-model="item.bpom_md"></v-text-field>
        <label>Barcode: </label>
        <v-text-field solo placeholder="Barcode" v-model="item.barcode"></v-text-field>
        <v-row class="justify-space-around">
          <v-col cols="5">
            <label>Gross Weight</label>
            <v-text-field solo placeholder="Gross Weight" prefix="KG | " v-model="item.gross_weight">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <label>Net Weight</label>
            <v-text-field solo placeholder="Net Weight" prefix="KG |" v-model="item.net_weight">
            </v-text-field>
          </v-col>
        </v-row>
        <label>Schedule Post: </label>
        <div>
          <date-picker v-model="item.schedule_time" type="datetime" class="date-picker" style="width:100%;"
            valueType="format" placeholder="Date for post Product" :formatter="momentFormat"></date-picker>
        </div>
      </v-col>
      <v-col cols="5">
        <label>Inventory Account: </label>
        <v-text-field solo placeholder="Inventory Account"></v-text-field>
        <label>Meta Description: </label>
        <v-text-field solo
          placeholder="Slug Name e.g. Jual udang ebi kering premium tanpa garam bebas formalin Crystal Sea, untuk penambah bahan makanan alami. dibuat dengan bahan pilihan, aman untuk dilkonsumsi segala umur."
          v-model="item.meta_description"></v-text-field>
        <label>Meta Keywords: </label>
        <v-text-field solo placeholder="Slug Name e.g. ebi kualitas premium,udang ebi kering,jual ebi kering,dll"
          v-model="item.meta_keywords"></v-text-field>
        <label>COGS Account: </label>
        <v-text-field solo placeholder="COGS Account"></v-text-field>
        <label>Revenue Account: </label>
        <v-text-field solo placeholder="Revenue Account"></v-text-field>
        <label>Images</label><br />
        <v-row class="justify-content-start">
          <v-col cols="4">
            <div class="btn btn-upload d-flex justify-center align-center" @click="onButtonClick(0)">
              <div :style="imageSrc[0] == null ? 'display:none;' : ''" ref="wrapper0" class="btn btn-upload">
                <v-img ref="preview0" class="product-preview" :src="imageSrc[0]" />
              </div>
              <div :style="imageSrc[0] == null ? '' : 'display:none;'" ref="icon0">
                <v-icon hidden x-large>mdi-plus</v-icon>
              </div>
              <input ref="uploader0" class="d-none" type="file" accept="image/*" @change="onFileChanged" />
            </div>
            <br />
          </v-col>
          <v-col cols="4">
            <div class="btn btn-upload d-flex justify-center align-center" @click="onButtonClick(1)">
              <div :style="imageSrc[1] == null ? 'display:none;' : ''" ref="wrapper1" class="btn btn-upload">
                <v-img ref="preview1" class="product-preview" :src="imageSrc[1]" />
              </div>
              <div :style="imageSrc[1] == null ? '' : 'display:none;'" ref="icon1">
                <v-icon hidden x-large>mdi-plus</v-icon>
              </div>
              <input ref="uploader1" class="d-none" type="file" accept="image/*" @change="onFileChanged" />
            </div>
            <br />
          </v-col>
          <v-col cols="4">
            <div class="btn btn-upload d-flex justify-center align-center" @click="onButtonClick(2)">
              <div :style="imageSrc[2] == null ? 'display:none;' : ''" ref="wrapper2" class="btn btn-upload">
                <v-img ref="preview2" class="product-preview" :src="imageSrc[2]" />
              </div>
              <div :style="imageSrc[2] == null ? '' : 'display:none;'" ref="icon2">
                <v-icon hidden x-large>mdi-plus</v-icon>
              </div>
              <input ref="uploader2" class="d-none" type="file" accept="image/*" @change="onFileChanged" />
            </div>
            <br />
          </v-col>
        </v-row>
        <label>Videos</label><br />
        <v-row class="justify-content-start">
          <v-col cols="5">
            <video ref="previewvideo" controls autoplay class="product-preview">
              <source ref="sourcevideo" :src="imageSrc[3]" />
            </video>
          </v-col>
          <v-col cols="5">
            <div class="btn btn-upload d-flex justify-center align-center h-100" @click="onButtonClick('video')">
                <p>Add/Edit</p>
              <input ref="uploadervideo" class="d-none" type="file" accept="video/*" @change="onFileChanged" />
            </div>
          </v-col>
        </v-row>
        <label>Product Type</label>
        <v-row class="justify-space-around">
          <v-radio-group v-model="product_type" row>
            <v-radio label="Single" value="single"></v-radio>
            <v-radio label="Bundle" value="bundle"></v-radio>
            <v-radio label="Special Packaging" value="special"></v-radio>
          </v-radio-group>
        </v-row>
        <v-select v-if="product_type === 'bundle' || product_type === 'special'" label="Enter Product"
          append-icon="mdi-magnify" solo :items="products" item-text="name" return-object v-model="item.bundle_items"
          multiple>
          <template v-slot:item="{ item }">
            <v-list-item @click.stop="multipleSelection(item)">{{
              item.name
            }}</v-list-item>
          </template>
        </v-select>
        <v-chip class="ma-2 text--futura text--white" close color="black" v-for="(it, index) in item.bundle_items"
          :key="index" @click:close="removeBundle(index)">
          {{ it.name }}
        </v-chip>
        <v-row class="justify-space-around">
          <v-col cols="5">
            <label>Gross Price</label>
            <v-text-field solo placeholder="Enter Price" prefix="Rp | " @input="actionOnChangeGrossPrice"
              v-model="item.gross_price">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <label>Discount Product</label>
            <v-text-field solo placeholder="Enter Discount" suffix="%" :maxlength="2"
              :disabled="item.gross_price && item.gross_price != 0 ? false : true" @input="actionOnChangeDiscount"
              v-model="item.discount_product">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <label>Retail Price</label>
            <v-text-field solo placeholder="Enter Price" prefix="Rp | " @input="actionOnChangeRetailPrice"
              v-model="item.retail_price">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <label>Reseller Price</label>
            <v-text-field solo placeholder="Enter Price" prefix="Rp |" v-model="item.resaller_price">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-space-around">
          <v-col cols="5">
            <label>Wholesaler Price</label>
            <v-text-field solo placeholder="Enter Price" prefix="Rp | " v-model="item.wholesaler_price">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <label>Cost Price</label>
            <v-text-field solo placeholder="Enter Price" prefix="Rp |" v-model="item.cost_price">
            </v-text-field>
          </v-col>
        </v-row>
        <label>Status</label>
        <v-select solo label="Status Product" :items="status" item-text="text" item-value="value" v-model="item.status">
        </v-select>
        <div class="mt-5">
            <div v-for="(faq, index) in item.faqs.filter(faq => faq.locale == 'id_ID')" :key="index">
                  <v-row class="justify-space-around">
                    <v-col cols="6"> <p>Faq {{ index+1 }}</p></v-col>
                    <v-col cols="6"><div class="row justify-end" @click="deleteFaq(index)">
                    <v-icon hidden x-large>mdi-delete</v-icon>
                  </div></v-col>
                  </v-row>
              <label>Title </label>
              <v-text-field solo placeholder="Faq Title" v-model="faq.title"></v-text-field>
              <label>Body </label>
              <v-textarea solo placeholder="Faq Body" v-model="faq.body"></v-textarea>
              </div>
        </div>
        <div class="row justify-center" @click="addFaq">
          <v-icon hidden x-large>mdi-plus</v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-end">
      <v-col cols="2">
        <v-btn type="submit" class="text--futura text--white" dark block @click.prevent="saveProduct()"
          :loading="buttonLoading" :disabled="buttonLoading">
          {{ type.value == "new" ? "Save" : "Update" }}
          <template v-slot:loader>
            <v-progress-circular indeterminate color="black"></v-progress-circular>
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alert from "@/components/Alert.vue";
import { VueEditor } from "vue2-editor";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";

export default {
  name: "ProductForm",
  components: {
    Alert,
    VueEditor,
    DatePicker,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          status: 0,
          name: "",
          ecommerce_name: "",
          whatsapp_name: "",
          description: "",
          gross_weight: "",
          net_weight: "",
          retail_price: 0,
          resaller_price: 0,
          wholesaler_price: 0,
          cost_price: 0,
          photos: [],
          video: "",
          preview1: null,
          sku: "",
          bundle_items: [],
          bpom_md: "",
          barcode: "",
          category_id: "",
          slug: "",
          meta_title: "",
          meta_keywords: "",
          meta_description: "",
          faqs: [],
          content_box: "",
          schedule_time: "",
          discount_product: 0,
          gross_price: 0,
          tag_script: ""
        };
      },
    },
    type: {
      type: Object,
      default: () => {
        return {
          text: "Add New",
          value: "new",
        };
      },
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
        },
        parse: (value) => {
          return value ? new Date(value) : null;
        },
      },
      customToolbar: [
        [{ header: [false, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        // ["blockquote", "code-block"],
      ],
      product_type:
        !this.item.bundle_items || !this.item.bundle_items.length
          ? "single"
          : "bundle",
      status: [
        {
          text: "Inactive",
          value: 0,
        },
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Draft",
          value: 2,
        },
        {
          text: "Pending",
          value: 3,
        },
      ],
      imageSrc: [],
      mediaIndex: "0"
    };
  },
  created() {
    this.$store.dispatch("getProducts");
    this.$store.dispatch("getCategories");
  },
  watch: {
    '$store.state.product': function () {
      this.$store.state.product.ItemCatalogs.forEach(catalog => {
        console.log(catalog)
        this.imageSrc[catalog.priority] = `${process.env.VUE_APP_API_URL}/${catalog.media_url}`
      })
    }
  },
  computed: {
    products() {
      let filter_product = this.$store.state.products.filter((product) => {
        return product.bundle_items == null || !product.bundle_items.length;
      });
      return filter_product;
    },
    items() {
      if (this.item.bundle_items) {
        return this.item.bundle_items.map(({ id, sku, name }) => ({
          id,
          sku,
          name,
        }));
      } else {
        return [];
      }
    },
    categories() {
      return this.$store.state.categories;
    },
  },
  methods: {
    multipleSelection(item) {
      this.item.bundle_items.push({ ...item });
    },
    onFileChanged(event) {
      var reader = new FileReader();
      reader.onload = e => {
        if (this.mediaIndex == "video") {
          this.item.video = event.target.files[0];

          this.$refs.sourcevideo.src = URL.createObjectURL(event.target.files[0])
          this.$refs.previewvideo.load()
        } else {
          this.item.photos[this.mediaIndex] = event.target.files[0];
          this.$refs[`preview${this.mediaIndex}`].src = e.target.result
        }

        this.showPreview(this.mediaIndex)
      }
      reader.readAsDataURL(event.target.files[0]);
    },
    onButtonClick(index) {
      this.mediaIndex = index
      this.$refs[`uploader${this.mediaIndex}`].click();
    },
    removeBundle(index) {
      this.item.bundle_items.splice(index, 1);
    },
    saveProduct() {
      this.$emit("saveProduct", this.items);
    },
    actionOnChangeRetailPrice(retail_price) {
      let gross_price = parseInt(this.item.gross_price ?? 0)
      if (!gross_price || retail_price > gross_price || gross_price == 0) {
        this.item.discount_product = 0
      } else {
        this.item.discount_product = Math.round(((gross_price - retail_price) / gross_price) * 100)
      }
    },
    actionOnChangeDiscount(discount_product) {
      let gross_price = parseInt(this.item.gross_price ?? 0)
      if (!gross_price || gross_price == 0) {
        this.item.discount_product = 0
      } else {
        this.item.retail_price = Math.round(gross_price - (gross_price * (discount_product / 100)))
      }
    },
    actionOnChangeGrossPrice(gross_price) {
      let retail_price = parseInt(this.item.retail_price ?? 0)
      if (!retail_price || retail_price == 0 || !gross_price || gross_price < retail_price) {
        this.item.discount_product = 0
      } else {
        this.item.discount_product = Math.round(((gross_price - retail_price) / gross_price) * 100)
      }
    },
    showPreview(index) {
      this.$refs[`wrapper${index}`].style = ""
      this.$refs[`preview${index}`].style = ""
      this.$refs[`icon${index}`].style = "display: none;"
    },
    addFaq() {
      this.item.faqs.push({ title: '', body: '', locale: 'id_ID'})
      this.$forceUpdate()
    },
    deleteFaq(index) {
      this.item.faqs.splice(index, index)
      this.$forceUpdate()
    }
  },
};
</script>

<style>
.date-picker>div>input.mx-input {
  height: 48px;
  border-color: transparent;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>
